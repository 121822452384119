import React, { Fragment, useEffect, useState } from "react";
import httpCommon from "../../http-common";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../App";

export const useQuery = () => {
  const { search } = useLocation();

  //   console.log("search");
  //   console.log(search);

  const queryParams = new URLSearchParams(search);
  return queryParams;

  //return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const SubscriptionSuccessPage = (props) => {
  let query = useQuery();
  const charge_id = parseInt(query.get("charge_id"));
  const [charge, setCharge] = useState(null);
  const [shopId, setShopId] = useState(null);
  const [shopSubscription, setShopSubscription] = useState(null);
  const [chargeAcceptedSuccess, setChargeAcceptedSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // console.log("subscription success page");
    // console.log("props");
    // console.log(props);

    if (shopId !== null) {
      // console.log("get shop current subscription for shop " + shopId);

      try {
        httpCommon.get(`/shops/${shopId}/subscription`).then((res) => {
          // console.log("res");
          // console.log(res);

          setShopSubscription(res.data.recurring_application_charge);
        });
      } catch (e) {
        throw e;
      }
    }
  }, [shopId]);

  useEffect(() => {
    // console.log("current shopSubscription");
    // console.log(shopSubscription);

    // console.log("shopId " + shopId);
    // console.log("charge_id " + charge_id);

    if (shopId !== null && charge_id !== null) {
      // if (shopSubscription !== null && shopSubscription !== undefined) {
      //   console.log(
      //     "shopSubscription.recurring_application_charge.id = " +
      //       shopSubscription.id
      //   );
      //   console.log(shopSubscription.status);
      //   console.log(shopSubscription.id === charge_id);
      //   console.log(shopSubscription.status === "active");
      // }

      if (
        shopSubscription !== null &&
        shopSubscription !== undefined &&
        shopSubscription.hasOwnProperty("id") &&
        shopSubscription.id === charge_id &&
        shopSubscription.hasOwnProperty("status") &&
        shopSubscription.status === "active"
      ) {
        setErrorMessage(
          'This charge has already been accepted. Please manage your subscription on the <a href="/subscription">Subscription page</a>.'
        );
      } else if (
        shopSubscription === null ||
        shopSubscription === undefined ||
        (shopSubscription.hasOwnProperty("id") &&
          shopSubscription.id !== charge_id)
      ) {
        // console.log("useEffect trying to update with new charge...");

        // display info for charge accepted
        httpCommon
          .get(`shopify/recurring-application-charge/${charge_id}`)
          .then((res) => {
            // console.log("res");
            // console.log(res.data);

            setCharge(res.data.recurring_application_charge);

            return res.data;
          })
          .then((data) => {
            // console.log("data");
            // console.log(data);

            switch (data.recurring_application_charge.status) {
              case "active":
                // console.log("updating mongo and mysql for shop subscription");

                // update mongodb shop for current subscription
                httpCommon
                  .put(`shops/${shopId}/subscription/`, data)
                  .then((res) => {
                    // console.log("res2");
                    // console.log(res.data);
                    //return res.data;
                  });

                // update mysql shop for charge accepted
                httpCommon
                  .put(`subscriptions/charge-accepted`, data)
                  .then((res) => {
                    // console.log("res3");
                    // console.log(res.data);
                  });

                // mark other charges as canelled mysql
                httpCommon
                  .get(`shopify/recurring-application-charge`)
                  .then((res) => {
                    const charges = res.data.recurring_application_charges;

                    charges.map(async (aCharge) => {
                      if (
                        aCharge.id !== charge_id &&
                        aCharge.status === "cancelled"
                      ) {
                        // console.log("cancelling charge id " + aCharge.id);

                        httpCommon
                          .put(`subscriptions/update`, aCharge)
                          .then((res) => {
                            // console.log(res);
                          });
                      }
                    });
                  });

                setChargeAcceptedSuccess(true);

                // const body = {
                //   mysqlshopid: null,
                //   recurring_application_charge: data,
                //   subscription_type_id: data.name === "Basic" ? 1 : 2,
                //   mdb_object_shop_id: shopId,
                // };

                // httpCommon.put(`subscriptions/add`, body).then((res) => {
                //   console.log("res3");
                //   console.log(res.data);
                // });

                break;

              case "cancelled":
                setErrorMessage("This charge was cancelled.");
                break;

              case "declined":
                setErrorMessage("This charge  was declined by the merchant.");
                break;

              case "expired":
                setErrorMessage(
                  "This charge has expired. The app subscription wasn't approved by the merchant within two days of being created."
                );
                break;

              default:
                break;
            }
          });
      } else {
        // console.log("useEffect charge has already been accepted");
      }
    } else {
      // console.log("here");
    }
  }, [shopSubscription]);

  return (
    <div>
      <UserContext.Consumer>
        {(userCtx) => {
          setShopId(userCtx.userInfo.shopId);
        }}
      </UserContext.Consumer>
      {chargeAcceptedSuccess ? (
        <Fragment>
          <div
            className="sticky-md-top"
            style={{
              backgroundColor: "#f3f3f3",
              boxShadow: "0 .5rem 1rem -.5rem rgba(0,0,0,.15)",
            }}
          >
            <div className="row">
              <div className="col-12 mt-3 mb-1 ms-3">
                <h4 className="text-uppercase">Subscription Success</h4>
              </div>
            </div>
          </div>
          <div className="container m-5">
            <div>
              <p>
                Thank you for your business. To cancel your subscription, you
                can either uninstall the app through your Shopify Apps and Sales
                Channels settings, or manage your subscription on the{" "}
                <a href="/subscription">Subscription page</a>.
              </p>
            </div>
            <h4 className="text-decoration-underline">Confirmation</h4>
            {charge?.id !== undefined ? (
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td style={{ width: "200px" }}>Charge ID:</td>
                    <td className="fw-bold">{charge.id}</td>
                  </tr>
                  <tr>
                    <td>Status:</td>
                    <td className="fw-bold text-capitalize">{charge.status}</td>
                  </tr>
                  <tr>
                    <td>Plan:</td>
                    <td className="fw-bold">{charge.name}</td>
                  </tr>
                  <tr>
                    <td>Price:</td>
                    <td className="fw-bold">
                      ${charge.price} {charge.currency}
                    </td>
                  </tr>
                  <tr>
                    <td>Activated on:</td>
                    <td className="fw-bold">{charge.activated_on}</td>
                  </tr>
                  <tr>
                    <td>Number of trial days:</td>
                    <td className="fw-bold">{charge.trial_days}</td>
                  </tr>
                  <tr>
                    <td>Trial ends on:</td>
                    <td className="fw-bold">{charge.trial_ends_on}</td>
                  </tr>
                  <tr>
                    <td>Billing begins on:</td>
                    <td className="fw-bold">{charge.billing_on}</td>
                  </tr>
                </tbody>
              </table>
            ) : null}
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div
            className="sticky-md-top"
            style={{
              backgroundColor: "#f3f3f3",
              boxShadow: "0 .5rem 1rem -.5rem rgba(0,0,0,.15)",
            }}
          >
            <div className="row">
              <div className="col-12 mt-3 mb-1 ms-3">
                <h4 className="text-uppercase">Subscription Error</h4>
              </div>
            </div>
          </div>
          <div className="m-5 text-center">
            <h4
              dangerouslySetInnerHTML={{
                __html: errorMessage,
              }}
            ></h4>
          </div>
        </Fragment>
      )}
    </div>
  );
};
