//product pane: home/default TabPane for import-item component

export const ProductPane = ({
  importState,
  updateImportState,
  collections,
}) => {
  //passed state object and setState function for product-related fields

  const { title, product_type, vendor } = importState;
  const defaultCollection = importState.collection
    ? importState.collection
    : "DEFAULT";
  // console.log("default collection is " + defaultCollection);
  return (
    <div>
      <div className="row mb-4">
        <div className="col-12">
          <div className="form-floating">
            <input
              defaultValue={title}
              className="form-control"
              onChange={(e) => {
                updateImportState({
                  title: e.target.value,
                });
              }}
              id="txtProductTitle"
            />
            <label for="txtProductTitle">Product Title</label>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-6">
          <div className="form-floating">
            <select
              className="form-control"
              onChange={(e) =>
                updateImportState({
                  product_type: e.target.value,
                })
              }
              id="ddlProductType"
            >
              <option value={product_type}>{product_type}</option>
              <option value="asdf">asdf</option>
            </select>
            <label for="ddlProductType">Product Type</label>
          </div>
        </div>
        <div className="col-6">
          <div className="form-floating">
            <select
              className="form-control"
              onChange={(e) => {
                const selectedIndex = e.target.selectedIndex;
                if (selectedIndex !== 0) {
                  // console.log(selectedOption)
                  const selectedOption = e.target.selectedOptions[0];
                  // console.log("changed collection to" + selectedOption.value);
                  updateImportState({ collection: selectedOption.value });
                }
              }}
              defaultValue={defaultCollection}
              value={defaultCollection}
              id="ddlCollection"
            >
              <option value="DEFAULT" disabled hidden>
                Choose a collection
              </option>
              {collections.map((c) => {
                return <option value={c.id}>{c.title}</option>;
              })}
            </select>
            <label for="ddlCollection">Collection</label>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-6">
          <div className="form-floating">
            <input
              defaultValue={vendor}
              className="form-control"
              onChange={(e) =>
                updateImportState({
                  vendor: e.target.value,
                })
              }
              id="txtVendor"
            />
            <label for="txtVendor">Vendor</label>
          </div>
        </div>
        <div className="col-6">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="txtTags"
              placeholder="Tags"
            />
            <label for="txtTags">Tags</label>
          </div>
        </div>
      </div>
    </div>
  );
};
