import React, { Fragment, useEffect, useState } from "react";
import httpCommon from "../../http-common";

import logo from "../../assets/Jemmity_logo_230723.png";
import logoIconOnly from "../../assets/Jemmiguy_6.png";

import SideMenuItem from "../side-menu-item/side-menu-item.component";

const SideMenu = ({ selectedPage, iconsOnly, isAllowedShop }) => {
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);

  useEffect(() => {
    checkSubscriptions();
  }, []);

  const checkSubscriptions = async () => {
    // console.log("sideMenu checkSubscriptions");
    httpCommon.get(`shopify/recurring-application-charge`).then((res) => {
      //console.log(res);

      const activeSubscriptions = res.data.recurring_application_charges.filter(
        (item) => {
          return item.status === "active";
        }
      );

      if (activeSubscriptions.length > 0) {
        setHasActiveSubscription(true);
      }
    });
  };

  if (iconsOnly) {
    return (
      <div
        className="h-100 d-flex flex-column justify-content-between flex-shrink-0 bg-white"
        style={{ width: "4.5rem" }}
      >
        <div>
          <a
            href="/"
            className="d-flex justify-content-center align-items-center mt-2 mb-2 text-black text-decoration-none"
            style={{ border: "1px solid red;" }}
          >
            <img
              src={logoIconOnly}
              alt="JEMMITY"
              style={{ width: "80%", border: "1px solid red;" }}
            />
          </a>
          <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
            <SideMenuItem
              text="Home"
              url="/"
              iconClass="fa-solid fa-house"
              selected={selectedPage === "Home"}
              iconsOnly
            />
            <SideMenuItem
              text="Search Products"
              url="/search-products"
              iconClass="fas fa-search"
              selected={selectedPage === "Search Products"}
              iconsOnly
            />
            {isAllowedShop ? (
              <Fragment>
                {hasActiveSubscription ? (
                  <Fragment>
                    <SideMenuItem
                      text="Import List"
                      url="/imports"
                      iconClass="fas fa-cart-arrow-down"
                      selected={selectedPage === "Import List"}
                      iconsOnly
                    />
                    <SideMenuItem
                      text="My Products"
                      url="/my-products"
                      iconClass="fas fa-tags"
                      selected={selectedPage === "My Products"}
                      iconsOnly
                    />
                    <SideMenuItem
                      text="My Orders"
                      url="/orders"
                      iconClass="fas fa-cart-plus"
                      selected={selectedPage === "My Orders"}
                      iconsOnly
                    />
                  </Fragment>
                ) : null}

                {/*<SideMenuItem
            text="Notifications"
            url="/notifications"
            iconClass="far fa-bell"
            selected={selectedPage === "Notifications"}
            iconsOnly
    />*/}
                <SideMenuItem
                  text="Subscription"
                  url="/subscription"
                  iconClass="bi bi-sliders2-vertical"
                  selected={selectedPage === "Subscription"}
                  iconsOnly
                />
                <SideMenuItem
                  text="Settings"
                  url="/settings"
                  iconClass="fas fa-cog"
                  selected={selectedPage === "Settings"}
                  iconsOnly
                />

                <SideMenuItem
                  text="Support"
                  url="/support"
                  iconClass="far fa-question-circle"
                  selected={selectedPage === "Support"}
                  iconsOnly
                />
              </Fragment>
            ) : null}
          </ul>
        </div>
        <div className="m-3 mb-5 small">
          <p>
            <a href="mailto:support@jemmity.com">support@jemmity.com</a>
          </p>
          <p>
            <a
              href="https://jemmity.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    );
  } else
    return (
      <div
        className="h-100 d-flex flex-column justify-content-between"
        //className="d-flex flex-column flex-shrink-0 p-3 text-white bg-white ps-0"
        style={{
          width: "280px",
        }}
        //className="navbar navbar-expand-lg navbar-light bg-light"
      >
        <div>
          <a
            href="/"
            className="p-3 d-flex align-items-center mb-3 mb-md-0 me-md-auto text-black text-decoration-none"
          >
            <span className="fs-4">
              <img src={logo} alt="JEMMITY" style={{ width: "80%" }} />
            </span>
          </a>
          <ul className="nav nav-pills flex-column mb-auto">
            <SideMenuItem
              text="Home"
              url="/"
              iconClass="fa-solid fa-house"
              selected={selectedPage === "Home"}
            />
            <SideMenuItem
              text="Search Products"
              url="/search-products"
              iconClass="fas fa-search"
              selected={selectedPage === "Search Products"}
            />

            {isAllowedShop ? (
              <Fragment>
                {hasActiveSubscription ? (
                  <Fragment>
                    <SideMenuItem
                      text="Import List"
                      url="/imports"
                      iconClass="fas fa-cart-arrow-down"
                      selected={selectedPage === "Import List"}
                    />
                    <SideMenuItem
                      text="My Products"
                      url="/my-products"
                      iconClass="fas fa-tags"
                      selected={selectedPage === "My Products"}
                    />
                    <SideMenuItem
                      text="My Orders"
                      url="/orders"
                      iconClass="fas fa-cart-plus"
                      selected={selectedPage === "My Orders"}
                    />
                  </Fragment>
                ) : null}

                {/*<SideMenuItem
              text="Notifications"
              url="/notifications"
              iconClass="far fa-bell"
              selected={selectedPage === "Notifications"}
      />*/}
                <SideMenuItem
                  text="Subscription"
                  url="/subscription"
                  iconClass="bi bi-sliders2-vertical"
                  selected={selectedPage === "Subscription"}
                />

                <SideMenuItem
                  text="Settings"
                  url="/settings"
                  iconClass="fas fa-cog"
                  selected={selectedPage === "Settings"}
                />
                <SideMenuItem
                  text="Support"
                  url="/support"
                  iconClass="far fa-question-circle"
                  selected={selectedPage === "Support"}
                />
              </Fragment>
            ) : null}
          </ul>
        </div>
        <div className="m-3 mb-5 small">
          <p>
            Questions? Email us at{" "}
            <a href="mailto:support@jemmity.com">support@jemmity.com</a>
          </p>
          <p>
            <a
              href="https://jemmity.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    );
};

export default SideMenu;
