import React, { useState, useEffect, useCallback } from "react";
import httpCommon from "../../http-common";
import { UserContext } from "../../App";

import ProductCollection from "../../components/product-collection/product-collection.component";

export const MyProductsPage = () => {
  const [productsFiltered, setProductsFiltered] = useState(false);
  const [rebindAllProducts, setRebindAllProducts] = useState(false);
  const [isAllowedShop, setIsAllowedShop] = useState(null);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);

  useEffect(() => {
    checkSubscriptions();
  }, []);

  useEffect(() => {
    if (hasActiveSubscription && isAllowedShop === null) {
      httpCommon
        .get(`shopify/shop`)
        .then((res) => {
          return res;
        })
        .then((res) => {
          // console.log("product collection shop info");
          // console.log(res.data);

          if (res.data.country_code === "CA") setIsAllowedShop(true);
          else setIsAllowedShop(false);

          // test
          //setIsAllowedShop(false);
        })
        .catch((err) => {
          // console.error(err);
        });
    }
  }, [hasActiveSubscription]);

  const checkSubscriptions = async () => {
    // console.log("sideMenu checkSubscriptions");
    httpCommon.get(`shopify/recurring-application-charge`).then((res) => {
      // console.log(res);

      const activeSubscriptions = res.data.recurring_application_charges.filter(
        (item) => {
          return item.status === "active";
        }
      );

      if (activeSubscriptions.length > 0) {
        setHasActiveSubscription(true);
      }
    });
  };

  const updateProductsFiltered = useCallback(
    (val) => {
      setProductsFiltered(val);
    },
    [setProductsFiltered]
  );

  const updateRebindAllProducts = useCallback(
    (val) => {
      setRebindAllProducts(val);
    },
    [setRebindAllProducts]
  );

  return (
    <div>
      <div
        className="sticky-md-top"
        style={{
          backgroundColor: "#f3f3f3",
          boxShadow: "0 .5rem 1rem -.5rem rgba(0,0,0,.15)",
        }}
      >
        <div className="row">
          <div className="col-12 mt-3 mb-1 ms-3">
            <h4 className="text-uppercase">My Products</h4>
          </div>
        </div>
      </div>
      <div>
        <UserContext.Consumer>
          {(value) => (
            <ProductCollection
              productsUrl={"/shopify/products-paged/"}
              userCtx={value}
              productsFiltered={productsFiltered}
              updateProductsFiltered={updateProductsFiltered}
              rebindAllProducts={rebindAllProducts}
              updateRebindAllProducts={updateRebindAllProducts}
              isAllowedShop={isAllowedShop}
              hasActiveSubscription={hasActiveSubscription}
            />
          )}
        </UserContext.Consumer>
      </div>
    </div>
  );
};
