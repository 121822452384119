import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import httpCommon from "../../http-common";

const initialOptions = {
  "client-id":
    "ATn7yCYOm8a4G9wkccgbpChFcQfU-7KXZ5nlKABRUjw-zYOduCPaEYUnqJJLxsJ9I440EWP5IIanHXux",
  currency: "CAD",
  intent: "capture",
  //"data-client-token": "abc123xyz==",
};

const buttonStyle = {
  layout: "vertical",
  color: "gold",
  shape: "rect",
  label: "paypal",
};

const PayPalButton2 = ({
  order,
  orderTotal,
  description,
  lineItems,
  subTotal,
  tax,
  shippingCost,
  userInfo,
}) => {
  const createOrder = (data, actions) => {
    // console.log("createOrder");

    // console.log("order");
    // console.log(order);

    // console.log("lineItems");
    // console.log(lineItems);

    const items = lineItems.map((item) => ({
      name: item.name,
      description: item.name,
      unit_amount: {
        currency_code: "CAD",
        value: item.vendor_price,
      },
      tax: {
        currency_code: "CAD",
        value: item.tax_lines.length > 0 ? item.tax_lines[0].price : 0,
      },
      quantity: item.quantity,
      sku: item.sku,
    }));

    // console.log("createOrder items");
    // console.log(items);

    // console.log("orderTotal = " + orderTotal);
    // console.log("orderTotal rounded = " + orderTotal.toFixed(2));
    // console.log("subTotal = " + subTotal);
    // console.log("shippingCost = " + shippingCost);
    // console.log("shippingCost rounded = " + shippingCost.toFixed(2));
    // console.log("tax = " + tax);

    return actions.order.create({
      purchase_units: [
        {
          description: description,
          amount: {
            currency_code: "CAD",
            value: orderTotal.toFixed(2),
            breakdown: {
              item_total: { currency_code: "CAD", value: subTotal.toFixed(2) },
              shipping: {
                currency_code: "CAD",
                value: shippingCost.toFixed(2),
              },
              tax_total: { currency_code: "CAD", value: tax.toFixed(2) },
            },
          },
          items: items,
        },
      ],
    });
  };

  const cancelOrder = (data, actions) => {
    // console.log("cancelOrder");
    // console.log(data);

    actions.redirect(
      `https://app.jemmity.com/orders/payment/cancel/${order.order_number}`
    );
  };

  const captureOrder = async (data, actions, the_order) => {
    const order = await actions.order.capture().then(async (details) => {
      // console.log("captureOrder details");
      // console.log(details);
      const name = details.payer.name.given_name;
      // console.log(name);

      // save into DB
      //const results = await addShop();
      const mysql_shop = await getShop();
      const mysql_shop_id = mysql_shop.shop_id;
      // console.log("captureOrder mysql_shop_id = " + mysql_shop_id);

      // const order_payment = await addOrderPayment(mysql_shop_id, details);
      // console.log("captureOrder order_payment");
      // console.log(order_payment);
      const payment = await addOrderPayment(mysql_shop_id, details);
      // console.log(payment);

      // console.log("the_order");
      // console.log(the_order);

      // update fulfillment orders in mongo
      // const fulfillment_orders = await httpCommon.get(
      //   `shopify/fulfillment-orders/${the_order.id}`
      // );
      // console.log("fulfillment_orders");
      // console.log(fulfillment_orders);

      // await httpCommon.put(
      //   `shops/${userInfo.shopId}/fulfillment_orders/${the_order.id}`,
      //   fulfillment_orders
      // );

      // console.log("done saving fulfillment orders to mongo");

      await httpCommon
        .put(`shops/${userInfo.shopId}/update-jemmity-status/${the_order.id}`, {
          status: "Paid",
        })
        .then((res) => {
          // console.log("res");
          // console.log(res);

          return res;
        })
        .catch((err) => {
          // console.log("error1");
          // console.log(err);
        });

      let fulfillment_orders;

      await httpCommon
        .get(`shopify/fulfillment-orders/${the_order.id}`)
        .then((res) => {
          // console.log("res");
          // console.log(res);

          fulfillment_orders = res.data;

          return res;
        })
        .catch((err) => {
          // console.log("error1");
          // console.log(err);
        });

      // console.log("fulfillment_orders");
      // console.log(fulfillment_orders);

      await httpCommon
        .put(
          `shops/${userInfo.shopId}/fulfillment_orders/${the_order.id}`,
          fulfillment_orders
        )
        .then((res) => {
          // console.log("res");
          // console.log(res);
          return res;
        })
        .catch((err) => {
          // console.log("error2");
          // console.log(err);
        });

      // Sends a fulfillment request to the fulfillment service of a fulfillment order.
      // const fulfillmentOrderId = fulfillment_orders[0].id;
      // const fulfillment_order_line_items = fulfillment_orders[0].line_items;

      // console.log("fulfillmentOrderId = " + fulfillmentOrderId);
      // console.log("fulfillment_order_line_items");
      // console.log(fulfillment_order_line_items);

      // const fulfillment_request_response = await httpCommon.post(
      //   `shopify/send-fulfillment-request/${fulfillmentOrderId}`,
      //   fulfillment_order_line_items
      // );

      // console.log("fulfillment_request_response");
      // console.log(fulfillment_request_response);

      // // save response in mongo
      // await httpCommon.put(
      //   `shops/${userInfo.shopId}/fulfillment_request/${the_order.id}`,
      //   fulfillment_request_response
      // );

      // console.log("done saving fulfillment_request_response to mongo");

      // redirect to success/receipt page
      // console.log("need to redirect to success/receipt page");
      actions.redirect(
        `https://app.jemmity.com/orders/payment/success/${payment.payment_ref_number}`
      );
    });

    // console.log("paypal button onApprove");
    // console.log(order);

    return order;
  };

  // const addShop = async () => {
  //   return await httpCommon.post(`orders/addshop/`, {
  //     mongoShopObjectID: userInfo.shopId,
  //     shopName: userInfo.shop_name,
  //   });
  // };

  const getShop = async () => {
    await httpCommon.post(`orders/addshop/`, {
      mongoShopObjectID: userInfo.shopId,
      shopName: userInfo.shop_name,
    });

    let response = await httpCommon
      .get(`orders/shop/${userInfo.shopId}`)
      .then((res) => {
        return res.data;
      });
    // console.log("response.data");
    // console.log(response);

    return response;
  };

  const addOrderPayment = async (mysql_shop_id, paypalDetails) => {
    await httpCommon.post(`orders/addorderpayment/`, {
      shop_id: mysql_shop_id,
      shopify_order_id: order.id,
      shopify_order_number: order.order_number,
      subtotal: subTotal,
      tax: tax,
      total: orderTotal,
      payment_date: paypalDetails.create_time,
      payment_ref_number:
        paypalDetails.purchase_units[0].payments.captures[0].id,
      paypal_order_id: paypalDetails.id,
      paypal_order_object: JSON.stringify(paypalDetails),
    });

    let response = await httpCommon
      .get(`orders/orderpayment/${order.order_number}`)
      .then((res) => {
        return res.data;
      });

    // console.log("addOrderPayment");
    // console.log(response);

    let payment_id;
    if (response.length === 0) {
      payment_id = -1;
    } else {
      payment_id = response.payment_id;
    }

    // console.log("payment_id = " + payment_id);

    return response;
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <PayPalButtons
        style={buttonStyle}
        forceReRender={[
          orderTotal,
          description,
          lineItems,
          subTotal,
          tax,
          shippingCost,
        ]}
        fundingSource="paypal"
        createOrder={(data, actions) => createOrder(data, actions)}
        onApprove={(data, actions) => captureOrder(data, actions, order)}
        onError={(err) => {
          // console.log("Error occurred");
          // console.log(err);
        }}
        onCancel={(data, actions) => cancelOrder(data, actions)}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalButton2;
