import { useState, useEffect } from "react";

import SideMenu from "../../components/side-menu/side-menu.component";

//Dependencies
import { Switch, Route } from "react-router-dom";

//Pages
import { HomePage } from "../home/home.component";
import { SearchPage } from "../search/search.component";
import { Imports } from "../imports/imports.component";
import { MyProductsPage } from "../my-products/my-products.component";
import { Orders } from "../orders/orders.component";
//import { NotificationsPage } from "../notifications/notifications.component";
import { SupportPage } from "../support/support.component";
import { SettingsPage } from "../settings/settings.component";
import { PaymentCancelledPage } from "../orders/payment-cancelled.component";
import { PaymentSuccessPage } from "../orders/payment-success.component";
import { SubscriptionPage } from "../subscription/subscription.component";
import { SubscriptionSuccessPage } from "../subscription/subscription-success.component";
//import { PrivacyPage } from "../privacy-policy/privacy-policy.component";

import httpCommon from "../../http-common";

function Dashboard() {
  const [width, setWidth] = useState(window.innerWidth);

  const [isAllowedShop, setIsAllowedShop] = useState(null);

  useEffect(() => {
    if (isAllowedShop === null) {
      httpCommon
        .get(`shopify/shop`)
        .then((res) => {
          return res;
        })
        .then((res) => {
          // console.log("product collection shop info");
          // console.log(res.data);

          if (res.data.country_code === "CA") setIsAllowedShop(true);
          else setIsAllowedShop(false);

          // test
          //setIsAllowedShop(false);
        })
        .catch((err) => {
          // console.error(err);
        });
    }
  }, []);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  // return (
  //   <div className="dashboard">
  //     <div
  //       className="row"
  //       style={{ height: "100vh", marginLeft: "0px", marginRight: "0px" }}
  //     >
  //       <div
  //         className="col-md-2 shadow"
  //         style={{
  //           position: "fixed",
  //           top: 0,
  //           left: 0,
  //           padding: "0px",
  //           height: "100vh",
  //           //boxShadow: "0 .5rem 1rem -2px rgba(0,0,0,.15)",
  //         }}
  //       >
  //         <SideMenu selectedPage="Notifications" />
  //       </div>
  //       <div
  //         className="col-md-10 offset-md-2"
  //         style={{
  //           backgroundColor: "#f3f3f3",
  //           padding: "0px",
  //           //borderLeft: "1px solid gray",
  //         }}
  //       >
  //         <Switch>
  //           <Route exact path="/" component={HomePage} />
  //           <Route exact path="/search-products" component={SearchPage} />
  //           <Route exact path="/my-products" component={MyProductsPage} />
  //           <Route exact path="/imports" component={Imports} />
  //           <Route exact path="/orders" component={Orders} />
  //           <Route exact path="/notifications" component={NotificationsPage} />
  //           <Route exact path="/help-centre" component={HelpCentrePage} />
  //           <Route exact path="/settings" component={SettingsPage} />
  //         </Switch>
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <div
    //className="d-flex justify-content-start"
    >
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          padding: "0px",
          height: "100vh",
        }}
      >
        <SideMenu
          selectedPage="Notifications"
          iconsOnly={width <= 768}
          isAllowedShop={isAllowedShop}
        />
      </div>
      <div
        //className="flex-grow-1"
        style={{
          backgroundColor: "#f3f3f3",
          marginLeft: width <= 768 ? "75px" : "285px",
          minHeight: "100vh",
        }}
      >
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/search-products" component={SearchPage} />
          <Route exact path="/my-products" component={MyProductsPage} />
          <Route exact path="/imports" component={Imports} />
          <Route exact path="/orders" component={Orders} />
          {/*<Route exact path="/notifications" component={NotificationsPage} />*/}
          <Route exact path="/support" component={SupportPage} />
          <Route exact path="/settings" component={SettingsPage} />
          <Route exact path="/subscription" component={SubscriptionPage} />
          <Route
            exact
            path="/subscription/success"
            component={SubscriptionSuccessPage}
          />
          {/*<Route exact path="/privacy-policy" component={PrivacyPage} />*/}
          <Route
            path="/orders/payment/success/:payment_ref_number"
            component={PaymentSuccessPage}
          />
          <Route
            path="/orders/payment/cancel/:shopify_order_number"
            component={PaymentCancelledPage}
          />
        </Switch>
      </div>
    </div>
  );
}

export default Dashboard;
