import React, { Fragment, useState } from "react";

//import ProductCardModal from "../product-card-modal/product-card-modal.component";

import { ProductCardContainer } from "./product-card.styles";

import httpCommon from "../../http-common";

const ProductCard = (props) => {
  // console.log(props);
  const {
    isImported = false,
    title = "Product Title",
    vendor = "ABCD",
    variants = [
      {
        title: "Blue version",
        price: 20,
        grams: 0.2,
        inventory_quantity: 100,
        sku: 987654321,
      },
    ],
    images = [
      {
        src: "https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg",
      },
    ],
    product_number = "N/A",
  } = props;

  // const [importStatus, setImportStatus] = useState(isImported);

  const { price } = variants[0];

  const productId = props._id;

  const { userInfo, setUserInfo } = props.userCtx;

  const { isShopifyProduct } = props;

  const { handleShowModal } = props;
  const { product } = props;
  const { updateRebindAllProducts } = props;
  const { hasActiveSubscription, IsMaxProductsImported } = props;

  //const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // const removeProduct = () => {
  //   httpCommon
  //     .delete(`shops/${userInfo.shopId}/imports/${importData._id}`)
  //     .then((res) => {
  //       console.log(res);
  //       return res;
  //     })
  //     .then((res) => {
  //       console.log("setting user info");
  //       setUserInfo({
  //         ...userInfo,
  //         importIds: userInfo.importIds.filter((id) => id !== props._id),
  //       });
  //       return res;
  //     })
  //     .then((res) => {
  //       console.log("deleting import from import list");
  //       deleteImportByProductId(importData._id);
  //     })
  //     .catch((e) => {
  //       console.error("something went wrong" + e);
  //     });
  // };

  const renderButtons = () => {
    // case "imported":
    //   return (
    //     <button className="btn btn-danger">
    //       <i class="far fa-trash-alt"></i> Remove from store
    //     </button>
    //   );

    if (hasActiveSubscription) {
      if (isImported) {
        // console.log("import list button found");
        return (
          <a href="/imports" className="btn btn-info">
            <i className="far fa-eye"></i> View in Import List
          </a>
        );
      } else if (isShopifyProduct) {
        return (
          <div className="row">
            <div className="col-6">
              <button
                className="btn btn-primary w-100"
                onClick={(event) => {
                  event.preventDefault();
                  handleShowModal(product);
                }}
              >
                Edit
              </button>
            </div>
            <div className="col-6">
              <button
                className="btn btn-light btn-outline-secondary w-100"
                onClick={async () => {
                  if (window.confirm("Are you sure you want to delete this?")) {
                    // console.log("delete product info" + product.id);

                    httpCommon
                      .delete(`shopify/products/${product.id}`)
                      .then((res) => {
                        // console.log(res);
                        // console.log("product card - deleted");

                        // reload my products list
                        updateRebindAllProducts(true);
                      });
                  } else {
                    // console.log("cancelled deleting product " + product.id);
                  }
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      } else if (!IsMaxProductsImported) {
        return (
          <button
            className="btn btn-primary"
            onClick={(e) => {
              httpCommon
                .post("/shops/" + userInfo.shopId + "/imports/" + productId)
                .then((res) => {
                  if (res.status !== 200) throw new Error(res.data.error);
                  else {
                    // setImportStatus('import list');
                    setUserInfo({
                      ...userInfo,
                      importIds: [...userInfo.importIds, productId],
                    });
                  }
                })
                .catch((e) => {
                  // console.error(e);
                });
            }}
          >
            <i className="far fa-plus-square"></i> Add to Import List
          </button>
        );
      }
    }
  };

  return (
    <Fragment>
      <ProductCardContainer className="card h-100" style={{ flexGrow: 1 }}>
        <div
          className="card-body"
          //onClick={handleShow}
          onClick={(event) => {
            event.preventDefault();
            handleShowModal(product);
          }}
        >
          <div className="d-flex justify-content-center">
            <img
              alt="None"
              src={
                images !== undefined && images.length > 0 ? images[0].src : null
              }
              style={{ height: "200px", width: "200px", objectFit: "cover" }}
            />
          </div>
          <div
            className="fw-bolder text-end ms-2 float-end"
            style={{
              fontFamily: "sans-serif",
              color: "#00C3D2",
            }}
          >
            ${parseFloat(price).toFixed(2)}
            <br />
            CAD
          </div>
          <div className="card-title">{title}</div>

          <div className="card-text">
            <div>Supplier: {vendor}</div>
            <div>Product #{product_number}</div>
          </div>
        </div>
        <div className="card-footer">
          <div className="d-grid gap-2">{renderButtons()}</div>
        </div>
      </ProductCardContainer>
      {/*<ProductCardModal show={show} handleClose={handleClose} {...props} />*/}
    </Fragment>
  );
};

export default ProductCard;
