import React, { Fragment, useEffect, useState } from "react";
import httpCommon from "../../http-common";

const Profile = (props) => {
  const { userInfo } = props.userCtx;
  const [storeOwnerName, setStoreOwnerName] = useState("");
  const [storeOwnerEmail, setStoreOwnerEmail] = useState("");

  useEffect(() => {
    // console.log("profile component userInfo");
    // console.log(userInfo);

    httpCommon.get(`shops/${userInfo.shopId}`).then((res) => {
      // console.log("settings page, mongo shop info");
      // console.log(res.data);

      const shopInfo = res.data;

      // console.log("shopInfo.hasOwnProperty(settings)");
      // console.log(shopInfo.hasOwnProperty("settings"));

      // console.log("shopInfo.settings.hasOwnProperty(profile)");
      // console.log(shopInfo.settings.hasOwnProperty("profile"));

      if (
        shopInfo.hasOwnProperty("settings") &&
        shopInfo.settings.hasOwnProperty("profile")
      ) {
        setStoreOwnerName(shopInfo.settings.profile.owner_name);
        setStoreOwnerEmail(shopInfo.settings.profile.owner_email);
      } else {
        httpCommon.get(`shopify/shop/`).then((res2) => {
          //   console.log("settings page, shopify shop info");
          //   console.log(res2.data);

          updateProfileSettings(res2.data.shop_owner, res2.data.email);

          setStoreOwnerName(res2.data.shop_owner);
          setStoreOwnerEmail(res2.data.email);
        });
      }
    });
  }, []);

  const updateProfileSettings = (name, email) => {
    //console.log("updateProfileSettings..." + name + " " + email);

    const body = {
      owner_name: name,
      owner_email: email,
    };

    httpCommon
      .post(`shops/${userInfo.shopId}/settings/profile`, body)
      .then((res3) => {
        // console.log("res3");
        // console.log(res3);
      });
  };

  const handleSaveChanges = () => {
    //console.log("handleSaveChanges...");
    updateProfileSettings(storeOwnerName, storeOwnerEmail);
  };

  return (
    <Fragment>
      <table className="table mt-3">
        <tr>
          <td className="bg-light text-left font-weight-bold">Your name</td>
          <td>
            <input
              type="text"
              value={storeOwnerName}
              className="form-control"
              onChange={(e) => {
                setStoreOwnerName(e.target.value);
              }}
            />
          </td>
        </tr>
        <tr>
          <td className="bg-light text-left font-weight-bold">Email</td>
          <td>
            <input
              type="email"
              value={storeOwnerEmail}
              className="form-control"
              onChange={(e) => {
                setStoreOwnerEmail(e.target.value);
              }}
            />
          </td>
        </tr>
      </table>
      <button
        type="button"
        className="btn btn-primary"
        onClick={(e) => {
          e.preventDefault();
          handleSaveChanges();
        }}
      >
        Save changes
      </button>
    </Fragment>
  );
};

export default Profile;
