import ShippingListJson from "../assets/shipping_fee_list_fedex.json";

const shippingMargin = 0.13;
const paypalMargin = 0.03;

export const getShippingPriceWon = (weightKg, country) => {
  // const arrByWeight = ShippingListJson["Shipping Fee by Weight"];
  // const arrByCountry = ShippingListJson["Region Number by Country"];
  let price = -1;

  //console.log("country = " + country);

  const arrByWeight = ShippingListJson["Shipping Fee by Weight"];
  const shippingListByWeight = arrByWeight.map(function (item) {
    for (var key in item) {
      var upper = key.toUpperCase();
      // check if it already wasn't uppercase
      if (upper !== key) {
        item[upper] = item[key];
        delete item[key];
      }
    }
    return item;
  });

  //const shippingListByCountry = ShippingListJson["Region Number by Country"];

  shippingListByWeight.every((element) => {
    if (element["WEIGHT"] > weightKg && price === -1) {
      try {
        price = element[country];
        if (price === undefined) price = -1;

        return true;
      } catch (err) {
        price = -1;
        return false;
      }
    }
    return true;
  });

  // if (price === -1) {
  //   //console.log("country = " + country);
  //   //country = country.toLocaleLowerCase();
  //   let ems = "--";

  //   shippingListByCountry.every((element) => {
  //     //console.log(element["Service Country"]);
  //     if (element["Service Country"].includes(country) && ems === "--") {
  //       // console.log(element["Service Country"]);
  //       // console.log("element[EMS] = " + element["EMS"]);
  //       ems = element["EMS"];
  //       return true;
  //     }
  //     return true;
  //   });

  //   //console.log("ems = " + ems);

  //   if (Number.isInteger(ems)) {
  //     country = "REGION " + ems;

  //     shippingListByWeight.every((element) => {
  //       if (element["WEIGHT"] > weightKg && price === -1) {
  //         try {
  //           price = element[country];
  //           if (price === undefined) price = -1;

  //           return true;
  //         } catch (err) {
  //           return false;
  //         }
  //       }
  //       return true;
  //     });
  //   }
  // }

  return price * (1 + shippingMargin + paypalMargin);
};
