import React, { Fragment, useEffect, useState } from "react";
import httpCommon from "../../http-common";
import { UserContext } from "../../App";
import moment from "moment";

export const SubscriptionPage = () => {
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [mysqlShopId, setMysqlShopId] = useState(null);
  const [shopId, setShopId] = useState(null);
  const [shopSubscription, setShopSubscription] = useState(null);
  const [shopifyProductListUndeleted, setShopifyProductListUndeleted] =
    useState(null);

  useEffect(() => {
    httpCommon.get("subscriptions/types").then((res) => {
      // console.log("SubscriptionPage subscription types");
      // console.log(res.data);

      setSubscriptionTypes(res.data);
    });

    // try {
    //   httpCommon
    //     .delete(`shopify/recurring-application-charge/27133182105`)
    //     .then((res) => {
    //       console.log("delete shopify recurring charge subscription useEffect");
    //       console.log(res);
    //     });
    // } catch (e) {
    //   throw e;
    // }

    getRecurringCharges();
  }, []);

  useEffect(() => {
    // console.log(
    //   "useEffect getting shop subscription " +
    //     subscriptionTypes.length +
    //     " " +
    //     shopId
    // );

    if (subscriptionTypes.length > 0 && shopId !== null) {
      httpCommon.get(`subscriptions/shop/${shopId}`).then((res) => {
        // console.log("shop subscription");
        // console.log(res);

        setShopSubscription(res.data[0]);
      });
    }

    getShopifyProductListUndeleted();
  }, [subscriptionTypes, shopId]);

  const setMySqlShopInfo = async (shopId, shopName) => {
    // console.log("setMySqlShopInfo " + shopId + " " + shopName);

    await httpCommon.post(`orders/addshop/`, {
      mongoShopObjectID: shopId,
      shopName: shopName,
    });

    let response = await httpCommon.get(`orders/shop/${shopId}`).then((res) => {
      return res.data;
    });
    // console.log("response.data");
    // console.log(response);

    //setMysqlShopId(response.shop_id);

    return response;
  };

  const createRecurringCharge = async (
    shop_id,
    subscription_type_id,
    subscription_type_desc,
    price_monthly_usd
  ) => {
    // console.log("createRecurringCharge");

    const body = {
      subscription_type_id: subscription_type_id,
      subscription_type_desc: subscription_type_desc,
      price_monthly_usd: price_monthly_usd,
      shop_id: shop_id,
    };

    // console.log(body);

    httpCommon
      .post(`shopify/recurring-application-charge`, body)
      .then((res) => {
        // console.log("created recurring charge res");
        // console.log(res);
        //getRecurringCharges();

        const confirmation_url =
          res.data.recurring_application_charge.confirmation_url;

        window.location.replace(confirmation_url);
      });
  };

  const cancelRecurringCharge = async (charge_id) => {
    // console.log("cancelRecurringCharge");
    // console.log(charge_id);

    httpCommon
      .delete(`shopify/recurring-application-charge/${charge_id}`)
      .then((res) => {
        // console.log("deleted recurring charge res");
        // console.log(res);

        httpCommon.get(`shopify/recurring-application-charge`).then((res) => {
          const recurring_application_charges =
            res.data.recurring_application_charges;

          // console.log("recurring_application_charges after cancel");
          // console.log(recurring_application_charges);

          var aCharge = recurring_application_charges.find((obj) => {
            return obj.id === parseInt(charge_id);
          });

          // console.log("aCharge");
          // console.log(aCharge);

          httpCommon.put(`subscriptions/update`, aCharge).then((res) => {
            // console.log(res);
          });

          let body = {
            recurring_application_charge: aCharge,
          };

          httpCommon.put(`shops/${shopId}/subscription`, body).then((res) => {
            // console.log(res);
          });

          // remove all of our products
          // httpCommon
          //   .get(`shops/${shopId}/shopify_product_list`)
          //   .then(async (res) => {
          //     console.log(res);

          //     const undeleted_shopify_product_list = res.data.filter((obj) => {
          //       return !obj.hasOwnProperty("date_deleted");
          //     });

          //     console.log("undeleted_shopify_product_list");
          //     console.log(undeleted_shopify_product_list);

          //     await Promise.all(
          //       undeleted_shopify_product_list.map(async (obj) => {
          //         httpCommon
          //           .delete(`shopify/products/${obj.shopify_product_id}`)
          //           .then((res) => {
          //             console.log(res);
          //             console.log(
          //               "shopify product - deleted " + obj.shopify_product_id
          //             );
          //           });
          //       })
          //     );
          //   });

          //mark all orders as deleted
          httpCommon.put(`shops/${shopId}/orders/delete-all`).then((res) => {
            // console.log("marked all orders as deleted");
            // console.log(res);
          });

          Promise.all(
            shopifyProductListUndeleted.map(async (obj) => {
              httpCommon.delete(`shopify/products/${obj.id}`).then((res) => {
                // console.log(res);
                // console.log("shopify product - deleted " + obj.id);
              });
            })
          );

          // delete any lingering shopify products from mongo
          httpCommon
            .put(`shops/${shopId}/shopify_product_list/delete-all`)
            .then((res) => {
              // console.log("marked all shopify products as deleted");
              // console.log(res);
            });

          setShopSubscription(null);

          window.location.reload();
        });
      })
      .then(() => {
        setShopSubscription(null);
      });
  };

  const getRecurringCharges = async () => {
    // console.log("getRecurringCharges");
    httpCommon.get(`shopify/recurring-application-charge`).then((res) => {
      // console.log(res);
    });
  };

  const getShopifyProductListUndeleted = async () => {
    // console.log("getShopifyProductListUndeleted");

    if (shopId !== null) {
      // httpCommon
      //   .get(`shops/${shopId}/shopify_product_list`)
      //   .then(async (res) => {
      //     console.log(res);

      //     const undeleted_shopify_product_list = res.data.filter((obj) => {
      //       return !obj.hasOwnProperty("date_deleted");
      //     });

      //     console.log("undeleted_shopify_product_list");
      //     console.log(undeleted_shopify_product_list);

      //     setShopifyProductListUndeleted(undeleted_shopify_product_list);
      //   });
      httpCommon.get(`shopify/products`).then(async (res) => {
        // console.log("res.data.products.length");
        // console.log(res.data.products.length);
        setShopifyProductListUndeleted(res.data.products);
      });
    }
  };

  return (
    <div>
      <div
        className="sticky-md-top"
        style={{
          backgroundColor: "#f3f3f3",
          boxShadow: "0 .5rem 1rem -.5rem rgba(0,0,0,.15)",
        }}
      >
        <div className="row">
          <div className="col-12 mt-3 mb-1 ms-3">
            <h4 className="text-uppercase">Subscription</h4>
          </div>
        </div>
      </div>
      <div className="mt-5 container">
        <UserContext.Consumer>
          {(userCtx) => (
            <Fragment>
              <div className="card-group">
                {setShopId(userCtx.userInfo.shopId)}

                {subscriptionTypes.map((item) => {
                  return (
                    <div
                      className={`card rounded-0 ${
                        shopSubscription?.subscription_type_id !== undefined &&
                        shopSubscription.subscription_type_id ===
                          item.subscription_type_id
                          ? "border-4 border-primary border-right border-left"
                          : null
                      }`}
                      style={
                        shopSubscription?.subscription_type_id !== undefined &&
                        shopSubscription.subscription_type_id ===
                          item.subscription_type_id
                          ? {
                              borderLeft: "2px solid blue",
                              borderRightWidth: "2px solid blue",
                            }
                          : null
                      }
                    >
                      <div class="card-header bg-light">
                        <h4 className="mb-0 text-center">
                          {item.subscription_type_desc}
                          {shopSubscription?.subscription_type_id !==
                            undefined &&
                          shopSubscription.subscription_type_id ===
                            item.subscription_type_id ? (
                            <span className="text-primary fw-bold ms-3">
                              Your Plan
                            </span>
                          ) : null}
                        </h4>
                      </div>
                      <div className="card-body">
                        <h1 className="card-title text-center mt-2">
                          ${item.price_monthly_usd.toString().split(".")[0]}
                          <span
                            style={{
                              fontSize: "18px",
                              position: "relative",
                              top: "-15px",
                            }}
                          >
                            .{item.price_monthly_usd.toString().split(".")[1]}{" "}
                            USD *
                          </span>
                        </h1>
                        <h4 className="text-center">monthly</h4>
                        <div className="d-flex justify-content-center">
                          <ul className="mt-4">
                            {item.benefits.map((b) => {
                              return (
                                <li>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: b.benefit_desc,
                                    }}
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="d-flex justify-content-center">
                          <span className="badge bg-warning text-black">
                            7-day free trial for new subscriptions only **
                          </span>
                        </div>
                        {shopSubscription?.subscription_type_id ===
                        undefined ? (
                          <div className="d-flex justify-content-center">
                            <button
                              className="btn btn-primary btn-lg mt-3"
                              onClick={async () => {
                                if (
                                  window.confirm(
                                    `Are you sure you want to subscribe to the ${item.subscription_type_desc} Plan?`
                                  )
                                ) {
                                  // console.log("subscribe plan");

                                  createRecurringCharge(
                                    userCtx.userInfo.shopId,
                                    item.subscription_type_id,
                                    item.subscription_type_desc,
                                    item.price_monthly_usd
                                  );
                                } else {
                                  // console.log("cancelled subscribing plan");
                                }
                              }}
                            >
                              SELECT
                            </button>
                          </div>
                        ) : null}

                        {shopSubscription?.subscription_type_id !== undefined &&
                        shopSubscription.subscription_type_id ===
                          item.subscription_type_id ? (
                          <div className="d-flex justify-content-center">
                            <button
                              className="btn btn-light btn-outline-secondary btn-lg mt-3"
                              onClick={async () => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to cancel your subscription? This will remove all of our products from your store and you will not be able to process unfulfilled orders for our products."
                                  )
                                ) {
                                  // console.log("cancelling plan");

                                  cancelRecurringCharge(
                                    shopSubscription.recurring_app_charge_id
                                  );
                                } else {
                                  // console.log("cancelled cancelling plan");
                                }
                              }}
                            >
                              CANCEL
                            </button>
                          </div>
                        ) : null}

                        {shopSubscription?.subscription_type_id !== undefined &&
                        shopSubscription.subscription_type_id !==
                          item.subscription_type_id ? (
                          <div className="d-flex justify-content-center">
                            <button
                              className="btn btn-light btn-outline-secondary btn-lg mt-3"
                              onClick={async () => {
                                if (
                                  shopSubscription.max_products_sourced >
                                    item.max_products_sourced &&
                                  shopifyProductListUndeleted.length >
                                    item.max_products_sourced
                                ) {
                                  alert(
                                    `The maximum number of products you can source from this plan is ${
                                      item.max_products_sourced
                                    } and you currently have ${
                                      shopifyProductListUndeleted.length
                                    } products imported. Please delete ${
                                      shopifyProductListUndeleted.length -
                                      item.max_products_sourced
                                    } from your My Products page in order to switch to this plan.`
                                  );
                                } else if (
                                  window.confirm(
                                    `Are you sure you want to switch to the ${item.subscription_type_desc} Plan?`
                                  )
                                ) {
                                  // console.log("switching plan");

                                  createRecurringCharge(
                                    userCtx.userInfo.shopId,
                                    item.subscription_type_id,
                                    item.subscription_type_desc,
                                    item.price_monthly_usd
                                  );
                                } else {
                                  // console.log("cancelled switching plan");
                                }
                              }}
                            >
                              SWITCH PLAN
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>

              {shopSubscription?.activated_on !== undefined ? (
                <div class="mt-4">
                  <h4>Details</h4>
                  <div class="fst-italic">
                    More details on billing can be found on your Shopify Apps
                    and Sales Channels settings
                  </div>
                  <table className="table bg-white table-striped border">
                    <tbody>
                      <tr>
                        <td style={{ width: "200px" }}>Price:</td>
                        <td className="fw-bold">
                          ${shopSubscription.price} {shopSubscription.currency}
                        </td>
                      </tr>
                      <tr>
                        <td>Activated on:</td>
                        <td className="fw-bold">
                          {moment(shopSubscription.activated_on).format(
                            "YYYY-MM-DD"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Number of trial days:</td>
                        <td className="fw-bold">
                          {shopSubscription.trial_days}
                        </td>
                      </tr>
                      <tr>
                        <td>Trial ends on:</td>
                        <td className="fw-bold">
                          {moment(shopSubscription.trial_ends_on).format(
                            "YYYY-MM-DD"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Billing begins on:</td>
                        <td className="fw-bold">
                          {moment(shopSubscription.billing_on).format(
                            "YYYY-MM-DD"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}
            </Fragment>
          )}
        </UserContext.Consumer>

        <div className="mt-5 mb-5" style={{ fontSize: "13px" }}>
          <p>
            * Please note that if you are using PayPal for subscription billing,
            as a Canadian customer, you will be charged in USD. If you are using
            a Canadian credit card, Shopify will bill you in CAD.
          </p>
          <p>
            For more information, please visit{" "}
            <a
              href="https://help.shopify.com/en/manual/your-account/manage-billing/your-invoice/local-currency"
              target="_blank"
            >
              Paying Shopify bills in your local currency · Shopify Help Center
            </a>
            .
          </p>
          <p>
            ** New subscriptions are the first time you install the application.
            If you uninstall and re-install the application, you will not
            receive a free trial.
          </p>
          <p>
            Uninstalling the app through your Shopify Apps and Sales Channels
            settings will automatically cancel your subscription. Cancelling
            your subscription will automatically remove all of our products from
            your store.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPage;
