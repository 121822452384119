import React, { useEffect, useState, Fragment, useRef } from "react";
import httpCommon from "../../http-common";
import ProductCard from "../product-card/product-card.component";
import ProductCardModal from "../product-card-modal/product-card-modal.component";

const ProductCollection = ({
  productsUrl,
  userCtx,
  categoryFilter = "",
  subcategoryFilter = "",
  productsFiltered = null,
  updateProductsFiltered = null,
  rebindAllProducts = null,
  updateRebindAllProducts = null,
  isAllowedShop = null,
  hasActiveSubscription = false,
}) => {
  // const { productsUrl } = props;
  // const { userCtx } = props;
  const { importIds, shopId } = userCtx.userInfo;
  const [products, setProducts] = useState([]);
  //const [displayedProducts, setDisplayedProducts] = useState([]);
  //const numberItemsPerLoad = 20;
  const [pageNumber, setPageNumber] = useState(0);
  // const [numPages, setNumPages] = useState(1);
  const [totalNumProducts, setTotalNumProducts] = useState(-1);
  // const [entriesPerPage, setEntriesPerPage] = useState(20);
  // const goToPageInput = useRef(null);

  const [productsLoaded, setProductsLoaded] = useState(false);
  const [allProductsLoaded, setAllProductsLoaded] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  //const [productsFiltered, setProductsFiltered] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalProduct, setModalProduct] = useState(null);
  const [modalIsImported, setModalIsImported] = useState(false);

  const [IsMaxProductsImported, setIsMaxProductsImported] = useState(false);
  const [nextPageLink, setNextPageLink] = useState(null);

  useEffect(() => {
    checkMaxProductsImported();
  }, [shopId]);

  useEffect(() => {
    if (
      productsUrl !== "/shopify/products-paged/" &&
      products.length === totalNumProducts
    ) {
      setAllProductsLoaded(true);
    }
  }, [totalNumProducts]);

  useEffect(() => {
    // console.log("product-collection useEffect");
    // console.log(
    //   "category = " +
    //     categoryFilter +
    //     ", subcategoryFilter = " +
    //     subcategoryFilter
    // );

    // console.log("isAllowedShop = " + isAllowedShop);
    // console.log("pageNumber = " + pageNumber);

    if (
      (productsLoaded === false || rebindAllProducts === true) &&
      isAllowedShop &&
      (hasActiveSubscription || productsUrl === "/products/")
    ) {
      // console.log("loading products...");

      // console.log("productsUrl");
      // console.log(productsUrl);

      if (pageNumber === 0 || rebindAllProducts === true) {
        //let prods = [];

        httpCommon
          .get(productsUrl + "?nextPageLink=0")
          .then((res) => {
            return res;
          })
          .then((res) => {
            if (productsUrl === "/shopify/products-paged/") {
              //prods = res.data.data.products;

              if (res.data.data.products.length > 0) {
                let link = res.data.link;
                // console.log("link before: " + link);

                if (link !== undefined) {
                  const endIndex = link.indexOf('; rel="next"');
                  link = link.substring(0, endIndex);
                  link = link.replace("<", "");
                  link = link.replace(">", "");

                  const startIndex = link.indexOf("/products.json");
                  link = link.substring(startIndex);

                  // console.log("link after: " + link);

                  setNextPageLink(link);
                }
              }

              setProducts(res.data.data.products);

              // console.log(
              //   "product-collection products.length: " +
              //     res.data.data.products.length
              // );
            } else {
              //prods = res.data.products;

              setProducts(res.data.products);
              setTotalNumProducts(res.data.total_results);
              // console.log(
              //   "product-collection products.length: " +
              //     res.data.products.length
              // );
            }

            // setTotalNumProducts(res.data.products.length);
            // setNumPages(Math.ceil(res.data.products.length / entriesPerPage));

            setProductsLoaded(true);

            updateRebindAllProducts(false);
            updateProductsFiltered(false);
          })
          .catch((err) => {
            //console.error(err);
          });

        setPageNumber(0);

        // if (prods.length === 0) {
        //   setAllProductsLoaded(true);
        // } else {
        //   setAllProductsLoaded(false);
        // }
      }
      // else {
      //   httpCommon
      //     .get(productsUrl + "?page=" + pageNumber)
      //     .then((res) => {
      //       return res;
      //     })
      //     .then((res) => {
      //       console.log("loadMoreProducts results");
      //       console.log(res.data.products);

      //       if (res.data.products.length > 0) {
      //         const arr = [...products];
      //         arr.push(...res.data.products);

      //         console.log('arr.push(...res.data.products)');
      //         console.log(arr);

      //         setProducts(arr);
      //         //filterProducts();
      //       } else {
      //         setAllProductsLoaded(true);
      //       }
      //       setProductsLoaded(true);
      //       updateProductsFiltered(false);
      //     })
      //     .catch((err) => {
      //       console.error(err);
      //     });
      // }
    }

    if (productsLoaded && productsFiltered === false) {
      // console.log("filtering products...");
      // console.log(products);

      filterProducts();

      // if (subcategoryFilter !== "") {
      //   console.log("trying to filter on subcategory...");

      //   const results = products.filter((product) => {
      //     if (product.hasOwnProperty("categories")) {
      //       return product.categories.find((category) => {
      //         return category.subcategories.includes(subcategoryFilter);
      //       });
      //     }
      //   });

      //   console.log("subcategoryFilter products");
      //   console.log(results);

      //   setFilteredProducts(results);
      // } else if (categoryFilter !== "") {
      //   console.log("trying to filter on category...");

      //   const results = products.filter((product) => {
      //     if (product.hasOwnProperty("categories")) {
      //       return product.categories.find((category) => {
      //         return category.name === categoryFilter;
      //       });
      //     }
      //   });

      //   console.log("categoryFilter products");
      //   console.log(results);

      //   setFilteredProducts(results);
      // } else {
      //   console.log("no filters...");
      //   setFilteredProducts(products);
      // }

      // updateProductsFiltered(true);
    }
  }, [
    isAllowedShop,
    // productsUrl,
    // pageNumber,
    productsLoaded,
    allProductsLoaded,
    categoryFilter,
    subcategoryFilter,
    productsFiltered,
    rebindAllProducts,
    filteredProducts,
    //products,
  ]);

  const filterProducts = () => {
    // console.log("filterProducts...");

    if (subcategoryFilter !== "") {
      // console.log("trying to filter on subcategory...");

      const results = products.filter((product) => {
        if (product.hasOwnProperty("categories")) {
          return product.categories.find((category) => {
            return category.subcategories.includes(subcategoryFilter);
          });
        }
      });

      // console.log("subcategoryFilter products");
      // console.log(results);

      setFilteredProducts(results);
    } else if (categoryFilter !== "") {
      // console.log("trying to filter on category...");

      const results = products.filter((product) => {
        if (product.hasOwnProperty("categories")) {
          return product.categories.find((category) => {
            return category.name === categoryFilter;
          });
        }
      });

      // console.log("categoryFilter products");
      // console.log(results);

      setFilteredProducts(results);
    } else {
      // console.log("no filters...");
      // console.log("products = ");
      // console.log(products);
      setFilteredProducts(products);
    }

    updateProductsFiltered(true);
  };

  const loadMoreProducts = () => {
    // console.log("loadMoreProducts...");

    const url =
      productsUrl +
      "?page=" +
      pageNumber +
      1 +
      "&nextPageLink=" +
      (nextPageLink !== null ? nextPageLink : "0");

    httpCommon
      .get(url)
      .then((res) => {
        return res;
      })
      .then((res) => {
        // console.log("loadMoreProducts results");

        let prods;
        let link = "";
        let loadedAllProducts = false;

        if (productsUrl === "/shopify/products-paged/") {
          // console.log(res.data.data.products);
          prods = res.data.data.products;

          if (prods.length > 0) {
            link = res.data.link;
            // console.log("link before: " + link);

            if (link !== undefined) {
              const endIndex = link.indexOf('; rel="next"');
              link = link.substring(0, endIndex);
              link = link.replace("<", "");
              link = link.replace(">", "");

              const startIndex = link.indexOf("/products.json");
              link = link.substring(startIndex);

              // console.log("link after: " + link + "-----");

              setNextPageLink(link);
            } else {
              loadedAllProducts = true;
              setAllProductsLoaded(true);
            }
          } else {
            loadedAllProducts = true;
            setAllProductsLoaded(true);
          }
        } else {
          // console.log(res.data.products);

          prods = res.data.products;
        }

        if (prods.length > 0 && loadedAllProducts === false) {
          const arr = [...products];
          arr.push(...prods);

          // console.log("arr.push(...res.data.products)");
          // console.log(arr);

          setProducts(arr);

          if (productsUrl === "/shopify/products-paged/" && link === "") {
            // console.log("my products loaded all products");
            setAllProductsLoaded(true);
          }
          //filterProducts();
        } else {
          setAllProductsLoaded(true);
        }

        setProductsLoaded(true);
        updateProductsFiltered(false);
      })
      .catch((err) => {
        // console.error(err);
      });

    setPageNumber(pageNumber + 1);
    //setProductsLoaded(false);
  };

  const handleCloseModal = () => {
    setModalIsImported(false);
    setModalProduct(null);
    setShowModal(false);
  };

  const handleShowModal = (product) => {
    // console.log("product-collection handleShowModal");

    if (productsUrl === "/products/") product.isMyProduct = false;
    else product.isMyProduct = true;

    // console.log(product);

    const isImported = importIds.some((x) => {
      return x === product._id;
    });

    setModalProduct(product);
    setModalIsImported(isImported);
    setShowModal(true);
  };

  const checkMaxProductsImported = () => {
    if (shopId) {
      // console.log("checkMaxProductsImported... ");

      httpCommon
        .get("/shopify/products/")
        .then((res) => {
          return res;
        })
        .then((res) => {
          const numProductsImported = res.data.products.length;
          // console.log("numProductsImported = " + numProductsImported);

          httpCommon.get(`subscriptions/shop/${shopId}`).then((res) => {
            if (
              res.data !== null &&
              res.data !== undefined &&
              res.data.length > 0
            ) {
              const maxProductsSourcedAllowed =
                res.data[0].max_products_sourced;

              // console.log(
              //   "maxProductsSourcedAllowed = " + maxProductsSourcedAllowed
              // );

              if (numProductsImported >= maxProductsSourcedAllowed) {
                setIsMaxProductsImported(true);
              }
            }
          });
        })
        .catch((err) => {
          // console.error(err);
        });
    }
  };

  return (
    <Fragment>
      {isAllowedShop ? (
        <div className="container" style={{ padding: "10px" }}>
          <div
            className="d-flex align-content-center flex-wrap"
            style={{ marginBottom: "20px" }}
          >
            {filteredProducts.map((product, idx) => {
              const isImported = importIds.some((x) => {
                return x === product._id;
              });
              return (
                <div style={{ padding: "10px" }}>
                  <ProductCard
                    isImported={isImported}
                    userCtx={userCtx}
                    {...product}
                    key={product._id}
                    isShopifyProduct={
                      productsUrl === "/shopify/products-paged/"
                    }
                    handleShowModal={handleShowModal}
                    product={product}
                    updateRebindAllProducts={updateRebindAllProducts}
                    hasActiveSubscription={hasActiveSubscription}
                    IsMaxProductsImported={IsMaxProductsImported}
                  />
                </div>
              );
            })}
          </div>
          <div
            className="d-flex justify-content-center"
            style={{
              marginBottom: "20px",
            }}
          >
            <button
              type="button"
              className="btn btn-info"
              onClick={(event) => {
                event.preventDefault();
                loadMoreProducts();
              }}
              style={{
                marginBottom: "20px",
                display: allProductsLoaded ? "none" : "inherit",
              }}
            >
              Load more
            </button>
          </div>
        </div>
      ) : null}

      {showModal ? (
        <ProductCardModal
          show={showModal}
          handleClose={handleCloseModal}
          product={modalProduct}
          userCtx={userCtx}
          isShopifyProduct={productsUrl === "/shopify/products-paged/"}
          isImported={modalIsImported}
          setIsImported={setModalIsImported}
          hasActiveSubscription={hasActiveSubscription}
          IsMaxProductsImported={IsMaxProductsImported}
        />
      ) : null}
    </Fragment>
  );
};

export default ProductCollection;
