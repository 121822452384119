import React, { useEffect, useState } from "react";
import httpCommon from "../../http-common";
import { Modal, Button } from "react-bootstrap";

import ShippingListJson from "../../assets/shipping_fee_list_fedex.json";

import { getShippingPriceWon } from "../../utils/helpers.js";

const ProductCardModal = (
  //   {
  //   show,
  //   handleClose,
  //   product,
  //   userCtx,
  //   isShopifyProduct,
  //   isImported,
  // }
  props
) => {
  const {
    show,
    handleClose,
    product,
    userCtx,
    isShopifyProduct,
    isImported,
    setIsImported,
  } = props;
  const { userInfo, setUserInfo } = userCtx;
  const { hasActiveSubscription, IsMaxProductsImported } = props;

  const {
    title = "Product Title",
    vendor = "ABCD",
    variants = [
      {
        title: "Blue version",
        price: 20,
        grams: 0.2,
        inventory_quantity: 100,
        sku: 987654321,
      },
    ],
    images = [
      {
        src: "https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg",
      },
    ],
    product_number = "N/A",
  } = product;
  const { price } = variants[0];

  const [showShippingCharges, setShowShippingCharges] = useState(false);
  const [shippingPrice, setShippingPrice] = useState("");
  const [productWeightKg, setProductWeightKg] = useState(null);
  const [countries, setCountries] = useState([]);
  // const [shippingListByWeight, setShippingListByWeight] = useState([]);
  // const [shippingListByCountry, setShippingListByCountry] = useState([]);
  const [shopName, setShopName] = useState("");

  useEffect(() => {
    if (product.isMyProduct) {
      // console.log("product-card-modal useEffect");
      // console.log(product);

      // httpCommon
      //   .get(`shops/${userInfo.shopId}/shopify_shop_data`)
      //   .then((res) => {
      //     // console.log("product-card-modal shopify_shop_data");
      //     // console.log(res.data);

      //     setShopName(res.data.name);
      //   });

      httpCommon.get(`shops/${userInfo.shopId}`).then((res) => {
        const name = res.data.shop_name.replace(".myshopify.com", "");
        setShopName(name);
      });
    }
  }, []);

  useEffect(() => {
    if (productWeightKg === null) {
      variants.some((variant, idx) => {
        if (productWeightKg === null) {
          //console.log(parseFloat(variant.grams) / 1000);
          setProductWeightKg(parseFloat(variant.grams) / 1000);
        }

        return true;
      });
    }
  }, [productWeightKg]);

  useEffect(() => {
    if (countries.length === 0) {
      let countries = ShippingListJson["Countries"];
      countries = countries.map(function (x) {
        return x.toUpperCase();
      });
      countries = countries.sort();

      setCountries(countries);
    }
  }, [countries]);

  // useEffect(() => {
  //   if (shippingListByWeight.length === 0) {
  //     const arrByWeight = ShippingListJson["Shipping Fee by Weight"];
  //     const arrByWeightUpper = arrByWeight.map(function (item) {
  //       for (var key in item) {
  //         var upper = key.toUpperCase();
  //         // check if it already wasn't uppercase
  //         if (upper !== key) {
  //           item[upper] = item[key];
  //           delete item[key];
  //         }
  //       }
  //       return item;
  //     });

  //     // console.log("arrByWeightUpper");
  //     // console.log(arrByWeightUpper);
  //     setShippingListByWeight(arrByWeightUpper);
  //   }
  // }, [shippingListByWeight]);

  // useEffect(() => {
  //   if (shippingListByCountry.length === 0) {
  //     const arrByCountry = ShippingListJson["Region Number by Country"];
  //     setShippingListByCountry(arrByCountry);
  //   }
  // }, [shippingListByCountry]);

  // const getShippingPriceWon = (weightKg, country) => {
  //   // const arrByWeight = ShippingListJson["Shipping Fee by Weight"];
  //   // const arrByCountry = ShippingListJson["Region Number by Country"];
  //   let price = -1;

  //   //console.log("country = " + country);

  //   shippingListByWeight.every((element) => {
  //     if (element["WEIGHT"] > weightKg && price === -1) {
  //       try {
  //         price = element[country];
  //         if (price === undefined) price = -1;

  //         return true;
  //       } catch (err) {
  //         price = -1;
  //         return false;
  //       }
  //     }
  //     return true;
  //   });

  //   if (price === -1) {
  //     //console.log("country = " + country);
  //     //country = country.toLocaleLowerCase();
  //     let ems = "--";

  //     shippingListByCountry.every((element) => {
  //       //console.log(element["Service Country"]);
  //       if (element["Service Country"].includes(country) && ems === "--") {
  //         // console.log(element["Service Country"]);
  //         // console.log("element[EMS] = " + element["EMS"]);
  //         ems = element["EMS"];
  //         return true;
  //       }
  //       return true;
  //     });

  //     //console.log("ems = " + ems);

  //     if (Number.isInteger(ems)) {
  //       country = "REGION " + ems;

  //       shippingListByWeight.every((element) => {
  //         if (element["WEIGHT"] > weightKg && price === -1) {
  //           try {
  //             price = element[country];
  //             if (price === undefined) price = -1;

  //             return true;
  //           } catch (err) {
  //             return false;
  //           }
  //         }
  //         return true;
  //       });
  //     }
  //   }

  //   return price;
  // };

  const renderButtons = () => {
    if (hasActiveSubscription) {
      if (isImported) {
        // console.log("import list button found");
        return (
          <a href="/imports" className="btn btn-info">
            <i className="far fa-eye"></i> View in Import List
          </a>
        );
      }
      // else if (isShopifyProduct) {
      //   return (
      //     <div className="row">
      //       <div className="col-6">
      //         <button className="btn btn-primary w-100">Edit</button>
      //       </div>
      //       <div className="col-6">
      //         <button className="btn btn-light btn-outline-secondary w-100">
      //           Delete
      //         </button>
      //       </div>
      //     </div>
      //   );
      // }
      else if (!IsMaxProductsImported) {
        return (
          <button
            className="btn btn-primary"
            onClick={(e) => {
              httpCommon
                .post("/shops/" + userInfo.shopId + "/imports/" + product._id)
                .then((res) => {
                  if (res.status !== 200) throw new Error(res.data.error);
                  else {
                    // setImportStatus('import list');
                    setUserInfo({
                      ...userInfo,
                      importIds: [...userInfo.importIds, product._id],
                    });
                    setIsImported(true);
                  }
                })
                .catch((e) => {
                  //console.error(e);
                });
            }}
          >
            <i className="far fa-plus-square"></i> Add to Import List
          </button>
        );
      }
    }
  };

  const handleShippingCountrySelected = (country) => {
    // console.log("handleShippingCountrySelected country = " + country);
    // console.log("productweight = " + productWeightKg);

    setShippingPrice(
      getShippingPriceWon(productWeightKg, country).toLocaleString() + " won"
    );
  };

  const renderBody = () => {
    if (product.isMyProduct) {
      return (
        <div>
          <div className="row">
            <div className="col-3 d-flex justify-content-center">
              <img
                alt="None"
                src={
                  images !== undefined && images.length > 0
                    ? images[0].src
                    : null
                }
                style={{ height: "200px", width: "200px", objectFit: "cover" }}
              />
            </div>
            <div className="col-9">
              <h3>{title}</h3>
              <h5>Supplier: {vendor}</h5>
              <h5>Product #{product_number}</h5>
              <h4
                style={{
                  fontFamily: "sans-serif",
                  color: "#00C3D2",
                  fontWeight: "bold",
                }}
              >
                ${parseFloat(price).toFixed(2)} CAD
              </h4>
              <div>
                <a
                  className="btn btn-primary me-2"
                  href={`https://admin.shopify.com/store/${shopName}/products/${product.id}`}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <i className="bi-pencil"></i> Edit on Shopify
                </a>
              </div>
            </div>
          </div>
          <table className="table mt-5">
            <thead>
              <tr>
                <th>#</th>
                <th>Variant Name</th>
                <th>Cost</th>
                <th>Weight</th>
                <th>SKU</th>
              </tr>
            </thead>
            <tbody>
              {variants.map((variant, idx) => {
                return (
                  <tr>
                    <td>{parseInt(idx) + 1}</td>
                    <td>{variant.title}</td>
                    <td>${parseFloat(variant.price).toFixed(2)} CAD</td>
                    {/*<td>{parseInt(variant.inventory_quantity)}</td>*/}
                    <td>{parseFloat(variant.grams)} g</td>
                    <td>{variant.sku}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div>
          <div className="row">
            <div className="col-3 d-flex justify-content-center">
              <img
                alt="None"
                src={
                  images !== undefined && images.length > 0
                    ? images[0].src
                    : null
                }
                style={{ height: "200px", width: "200px", objectFit: "cover" }}
              />
            </div>
            <div className="col-9">
              <h3>{title}</h3>
              <h5>Supplier: {vendor}</h5>
              <h5>Product #{product_number}</h5>
              <h4
                style={{
                  fontFamily: "sans-serif",
                  color: "#00C3D2",
                  fontWeight: "bold",
                }}
              >
                ${parseFloat(price).toFixed(2)} CAD
              </h4>
              <div class="d-flex" style={{ columnGap: "3px" }}>
                {/*<button className="btn btn-primary me-2">
                  <i className="far fa-plus-square"></i> Add to Import List
              </button>*/}
                {renderButtons()}
                <button
                  className="btn btn-info"
                  type="button"
                  onClick={(e) => {
                    setShippingPrice("");
                    setShowShippingCharges(!showShippingCharges);
                  }}
                >
                  <i className="far fa-eye"></i> View Shipping Charges
                </button>
              </div>

              <div
                className="card bg-light mt-3"
                style={
                  showShippingCharges
                    ? { display: "inherit" }
                    : { display: "none" }
                }
              >
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="text-nowrap me-1">Select country:</div>
                    <select
                      className="form-select"
                      onChange={(e) =>
                        handleShippingCountrySelected(e.target.value)
                      }
                    >
                      <option></option>
                      {countries.map((country) => (
                        <option value={country}>{country}</option>
                      ))}
                    </select>
                  </div>
                  <div className="mt-2">
                    Estimated shipping charges:
                    <span className="ms-1 fw-bold">{shippingPrice}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <table className="table mt-5">
            <thead>
              <tr>
                <th>#</th>
                <th>Variant Name</th>
                <th>Cost</th>
                <th>Weight</th>
                <th>SKU</th>
              </tr>
            </thead>
            <tbody>
              {variants.map((variant, idx) => {
                return (
                  <tr>
                    <td>{parseInt(idx) + 1}</td>
                    <td>{variant.title}</td>
                    <td>${parseFloat(variant.price).toFixed(2)} CAD</td>
                    {/*<td>{parseInt(variant.inventory_quantity)}</td>*/}
                    <td>{parseFloat(variant.grams)} g</td>
                    <td>{variant.sku}</td>
                    {/*<td>
                      <button className="btn btn-success btn-sm btn-block me-2">
                        <i className="fas fa-dollar-sign"></i> Buy It Now
                      </button>
                      <button className="btn btn-primary btn-sm btn-block">
                        <i className="fas fa-cart-plus"></i> Add to Cart
                      </button>
                </td>*/}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <h5 className="mt-4">Images</h5>
          <div className="d-flex justify-content-start flex-wrap">
            {images.map((img, index) => (
              <div className="d-flex flex-column align-items-end">
                <img
                  src={img.src}
                  alt={
                    img.hasOwnProperty("name") && img.name ? img.name : img.alt
                  }
                  style={{ height: "80px", marginRight: "15px" }}
                />
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderBody()}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductCardModal;
