import React, { useEffect, useState } from "react";
import httpCommon from "../../http-common";
import moment from "moment";

export const PaymentSuccessPage = (props) => {
  const [paymentRefNumber, setPaymentRefNumber] = useState("");
  const [payment, setPayment] = useState(null);
  const [paypalDetails, setPaypalDetails] = useState(null);
  const [paypalCapture, setPaypalCapture] = useState(null);

  useEffect(() => {
    setPaymentRefNumber(props.match.params.payment_ref_number);

    if (paymentRefNumber !== "" && payment === null) {
      httpCommon
        .get(`orders/orderpaymentbyref/${paymentRefNumber}`)
        .then((res) => {
          // console.log(res.data);
          setPayment(res.data);
          //setPaypalCapture(res.data.purchase_units[0].payments.captures[0]);
        });
    }

    if (payment !== null && paypalDetails === null) {
      setPaypalDetails(payment.paypal_order_object);
    }

    if (paypalDetails !== null && paypalCapture === null) {
      setPaypalCapture(paypalDetails.purchase_units[0].payments.captures[0]);
    }
  }, [paymentRefNumber, payment, paypalDetails, paypalCapture]);

  return (
    <div>
      <div
        className="sticky-md-top"
        style={{
          backgroundColor: "#f3f3f3",
          boxShadow: "0 .5rem 1rem -.5rem rgba(0,0,0,.15)",
        }}
      >
        <div className="row">
          <div className="col-12 mt-3 mb-1 ms-3">
            <h4 className="text-uppercase">Payment Success</h4>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div>
          Your PayPal payment was successful. Below is your payment
          confirmation. You can also find it on your Orders page under the
          "Ordered" tab for the specified order.
        </div>
        {paypalCapture !== null ? (
          <div className="mt-5 pb-3 border bg-white">
            <div
              className="row fw-bold pt-2 pb-2 text-white"
              style={{ backgroundColor: "#1747b5" }}
            >
              <div className="col-2 text-end fs-5">Confirmation #:</div>
              <div className="col-10 fs-5">{paypalCapture.id}</div>
            </div>
            <div className="row mt-3">
              <div className="col-2 text-end">Shopify Order #:</div>
              <div className="col-10">{payment.shopify_order_number}</div>
            </div>
            <div className="row mt-3">
              <div className="col-2 text-end">PayPal Order #:</div>
              <div className="col-10">{payment.paypal_order_id}</div>
            </div>
            <div className="row">
              <div className="col-2 text-end">Payment Date:</div>
              <div className="col-10">
                {moment(paypalCapture.create_time).format("MMMM d, yyyy")}
              </div>
            </div>
            <div className="row">
              <div className="col-2 text-end">Amount:</div>
              <div className="col-10">
                ${paypalCapture.amount.value}{" "}
                {paypalCapture.amount.currency_code}
              </div>
            </div>
            <hr className="ms-5 me-5 mt-4 mb-4" />
            <h5 className="ms-5">Your Contact Information</h5>

            <div className="row mt-3">
              <div className="col-2 text-end">Name:</div>
              <div className="col-10">
                {paypalDetails.payer.name.given_name}{" "}
                {paypalDetails.payer.name.surname}
              </div>
            </div>
            <div className="row">
              <div className="col-2 text-end">Email:</div>
              <div className="col-10">{paypalDetails.payer.email_address}</div>
            </div>
            <div className="row">
              <div className="col-2 text-end">Billing Address:</div>
              <div className="col-10">
                {paypalDetails.payer.address.address_line_1}
                <br />
                {paypalDetails.payer.address.admin_area_2}{" "}
                {paypalDetails.payer.address.admin_area_1}{" "}
                {paypalDetails.payer.address.postal_code}
                <br />
                {paypalDetails.payer.address.country_code}
              </div>
            </div>
          </div>
        ) : null}
        <div className="mt-5">
          <a href="/orders">Return to My Orders</a>
        </div>
      </div>
    </div>
  );
};
