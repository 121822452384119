import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  Fragment,
} from "react";
import { UserContext } from "../../App";
import httpCommon from "../../http-common";

import SearchBar from "../../components/search-bar/search-bar.component";
import FeaturedCollection from "../../components/featured-collection/featured-collection.component";
import ProductCollection from "../../components/product-collection/product-collection.component";

export const SearchPage = () => {
  const [categoryButtonsVisible, setCategoryButtonsVisible] = useState(true);
  const featuredCategories = useRef();
  const productsSection = useRef();
  const [categoryFilter, setCategoryFilter] = useState("");
  const [subcategoryFilter, setSubcategoryFilter] = useState("");
  const [productsFiltered, setProductsFiltered] = useState(false);
  const [rebindAllProducts, setRebindAllProducts] = useState(false);

  const [isAllowedShop, setIsAllowedShop] = useState(null);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [IsMaxProductsImported, setIsMaxProductsImported] = useState(false);
  const [maxProductsImportedAllowed, setMaxProductsImportedAllowed] =
    useState(null);
  const [shopId, setShopId] = useState(null);

  const updateCategoryFilter = useCallback(
    (val) => {
      setCategoryFilter(val);
    },
    [setCategoryFilter]
  );

  const updateSubcategoryFilter = useCallback(
    (val) => {
      setSubcategoryFilter(val);
    },
    [setSubcategoryFilter]
  );

  const updateCategorButtonsVisible = useCallback(
    (val) => {
      setCategoryButtonsVisible(val);
    },
    [setCategoryButtonsVisible]
  );

  const updateProductsFiltered = useCallback(
    (val) => {
      setProductsFiltered(val);
    },
    [setProductsFiltered]
  );

  const updateRebindAllProducts = useCallback(
    (val) => {
      setRebindAllProducts(val);
    },
    [setRebindAllProducts]
  );

  const scrollPastCategories = () => {
    //productsSection.current.scrollIntoView();
    //window.scrollTo(0, productsSection.current.offsetTop);

    const yOffset = -80;
    const y =
      productsSection.current.getBoundingClientRect().top +
      window.pageYOffset +
      yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  useEffect(() => {
    checkSubscriptions();
  }, []);

  useEffect(() => {
    checkMaxProductsImported();
  }, [shopId]);

  useEffect(() => {
    if (isAllowedShop === null) {
      httpCommon
        .get(`shopify/shop`)
        .then((res) => {
          return res;
        })
        .then((res) => {
          // console.log("product collection shop info");
          // console.log(res.data);

          if (res.data.country_code === "CA") setIsAllowedShop(true);
          else setIsAllowedShop(false);

          // test
          //setIsAllowedShop(false);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  useEffect(() => {
    // console.log("filters");
    // console.log(categoryFilter);
    // console.log(subcategoryFilter);

    const onScroll = (e) => {
      const top = featuredCategories.current.getBoundingClientRect().top;
      //console.log(featuredCategories.current.getBoundingClientRect());
      //here we check if element top reference is on the top of viewport
      /*
       * If the value is positive then top of element is below the top of viewport
       * If the value is zero then top of element is on the top of viewport
       * If the value is negative then top of element is above the top of viewport
       * */
      if (top <= 0) {
        setCategoryButtonsVisible(false);
        //console.log("Element is outside view");
      } else {
        setCategoryButtonsVisible(true);
        //console.log("Element is in view or above the viewport");
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [
    isAllowedShop,
    categoryFilter,
    subcategoryFilter,
    categoryButtonsVisible,
    rebindAllProducts,
  ]);

  const checkMaxProductsImported = () => {
    if (shopId) {
      // console.log("checkMaxProductsImported... ");

      httpCommon
        .get("/shopify/products/")
        .then((res) => {
          return res;
        })
        .then((res) => {
          const numProductsImported = res.data.products.length;
          // console.log("numProductsImported = " + numProductsImported);

          httpCommon.get(`subscriptions/shop/${shopId}`).then((res) => {
            if (
              res.data !== null &&
              res.data !== undefined &&
              res.data.length > 0
            ) {
              const maxProductsSourcedAllowed =
                res.data[0].max_products_sourced;
              setMaxProductsImportedAllowed(maxProductsSourcedAllowed);

              // console.log(
              //   "maxProductsSourcedAllowed = " + maxProductsSourcedAllowed
              // );

              if (numProductsImported >= maxProductsSourcedAllowed) {
                setIsMaxProductsImported(true);
              }
            }
          });
        })
        .catch((err) => {
          // console.error(err);
        });
    }
  };

  const checkSubscriptions = async () => {
    // console.log("sideMenu checkSubscriptions");
    httpCommon.get(`shopify/recurring-application-charge`).then((res) => {
      // console.log(res);

      const activeSubscriptions = res.data.recurring_application_charges.filter(
        (item) => {
          return item.status === "active";
        }
      );

      if (activeSubscriptions.length > 0) {
        setHasActiveSubscription(true);
      }
    });
  };

  return (
    <div>
      <div
        className="sticky-top"
        style={{
          backgroundColor: "#f3f3f3",
          boxShadow: "0 .5rem 1rem -.5rem rgba(0,0,0,.15)",
        }}
      >
        <div className="row">
          <div className="col-12 mt-3 ms-3">
            <h4 className="text-uppercase">Search Products</h4>
          </div>
        </div>
        {isAllowedShop ? (
          <div className="p-3 pt-0">
            <SearchBar
              displayCategoryDdl={
                !categoryButtonsVisible || categoryFilter !== ""
              }
              // filters={filters}
              // setFilters={setFilters()}
              categoryFilter={categoryFilter}
              subcategoryFilter={subcategoryFilter}
              updateCategoryFilter={updateCategoryFilter}
              updateSubcategoryFilter={updateSubcategoryFilter}
              updateProductsFiltered={updateProductsFiltered}
              //updateCategoriesVisible={updateCategoriesVisible}
              updateRebindAllProducts={updateRebindAllProducts}
            />
          </div>
        ) : null}
      </div>
      {IsMaxProductsImported ? (
        <div class="alert alert-warning fw-bold fst-italic m-3">
          You have imported the maximum allowed products of{" "}
          {maxProductsImportedAllowed} products from your current subscription
          plan. Please either remove some of our products from your product
          listing or upgrade your subscription (if applicable).
        </div>
      ) : null}
      {isAllowedShop ? (
        <div className="mt-3">
          <div ref={featuredCategories}>
            <UserContext.Consumer>
              {(value) => (
                <FeaturedCollection
                  userCtx={value}
                  updateCategoryFilter={updateCategoryFilter}
                  updateCategorButtonsVisible={updateCategorButtonsVisible}
                  scrollPastCategories={scrollPastCategories}
                  updateProductsFiltered={updateProductsFiltered}
                  updateRebindAllProducts={updateRebindAllProducts}
                  isAllowedShop={isAllowedShop}
                />
              )}
            </UserContext.Consumer>
          </div>
          <hr style={{ margin: 20, marginTop: 40, marginBottom: 25 }} />
          <div ref={productsSection}>
            <UserContext.Consumer>
              {(value) => (
                <Fragment>
                  {setShopId(value.userInfo.shopId)}
                  <ProductCollection
                    productsUrl={"/products/"}
                    userCtx={value}
                    categoryFilter={categoryFilter}
                    subcategoryFilter={subcategoryFilter}
                    productsFiltered={productsFiltered}
                    updateProductsFiltered={updateProductsFiltered}
                    rebindAllProducts={rebindAllProducts}
                    updateRebindAllProducts={updateRebindAllProducts}
                    isAllowedShop={isAllowedShop}
                    hasActiveSubscription={hasActiveSubscription}
                  />
                </Fragment>
              )}
            </UserContext.Consumer>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center mt-5">
          <h2 className="text-center">
            We are only accepting Canadian Shopify stores at this time.
            <br />
            Thank you for your understanding.
          </h2>
        </div>
      )}
    </div>
  );
};
