import { useEffect, useState } from "react";
import axios from "axios";
import ErrorModal from "../error-modal/error-modal.component";
import interpretAxiosErrors from "./interpretAxiosErrors";

function ErrorInterceptor() {

    const [errorState, setErrorState] = useState({
        showModal: false,
        content: {
            header: '',
            text: '',
        }
    });

    const setShowModal = (showModal) => {
        setErrorState({
            ...errorState,
            showModal: showModal
        });
    };


    useEffect(() => {
        const interceptorId = axios.interceptors.response.use((response) => {

            return response;
        },
            (error => {

                const newErrorState = interpretAxiosErrors(error);

                setErrorState(
                    newErrorState
                )

                return Promise.reject(error);
            }))

        //useEffect cleanup
        return () => { axios.interceptors.response.eject(interceptorId) };
    }, [])

    return <ErrorModal content={errorState.content} show={errorState.showModal} closeCallback={() => { setShowModal(false) }} />;
}

export default ErrorInterceptor;