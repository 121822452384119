import React, { useState, useEffect, Fragment } from "react";
import httpCommon from "../../http-common";

import { Modal, Button } from "react-bootstrap";

import PayPalButton from "../paypal-button/paypal-button.component";
import PayPalButton2 from "../paypal-button/paypal-button2.component";

import { CheckoutModalBodyContainer } from "./checkout-modal.styles";

import { getShippingPriceWon } from "../../utils/helpers.js";

const CheckoutModal = ({ show, handleClose, order, userInfo, krwRateInfo }) => {
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [shippingPrice, setshippingPrice] = useState(0);
  const [tax, setTax] = useState(0);

  // const [paymentSucceeded, setPaymentSucceeded] = useState(false);
  // const [paypalErrorMessage, setPaypalErrorMessage] = useState("");
  // const [paypalOrderID, setPaypalOrderID] = useState(false);
  // const [billingDetails, setBillingDetails] = useState("");
  // const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    // const testArray = [
    //   {
    //     id: 1,
    //     shipping_address: {
    //       first_name: "Jane",
    //       phone: null,
    //     },
    //   },
    //   {
    //     id: 2,
    //     shipping_address: {
    //       phone: null,
    //     },
    //   },
    // ];

    // const filteredArray = testArray.filter((item) =>
    //   item.shipping_address.hasOwnProperty("first_name")
    // );

    // console.log("filteredArray");
    // console.log(filteredArray);

    //console.log("CheckoutModal userInfo.shopId = " + userInfo.shopId);

    if (order !== null) {
      // console.log("CheckoutModal useEffect order");
      // console.log(order);

      let tempSubTotal = 0;
      let totalWeightGrams = 0;

      order.line_items.forEach((item) => {
        if (item.jemmity_data) {
          tempSubTotal += item.vendor_price * item.quantity;
          totalWeightGrams += item.grams;
        }
      });
      setSubTotal(tempSubTotal);

      const country = order.shipping_address.country.toUpperCase();
      //console.log("country = " + country);
      const weightKg = parseFloat(totalWeightGrams) / 1000;
      //console.log("weightKg = " + weightKg);
      const won = getShippingPriceWon(weightKg, country);
      // console.log("won = " + won);
      // console.log("cad rate = " + krwRateInfo["cad"]);
      const cad = won * krwRateInfo["cad"];
      //console.log("cad = " + cad);
      setshippingPrice(cad);
      //setshippingPrice(1.0);

      //setTax(order.total_tax);
      setTax(0);

      setTotal(subTotal + shippingPrice);
    }

    // dispatch({
    //   type: "resetOptions",
    //   value: {
    //     ...options,
    //     currency: currency,
    //   },
    // });
  }, [order, subTotal, shippingPrice, total, tax]);

  // const getShopifyShopInfo = async () => {
  //   console.log("getShopifyShopInfo...");

  //   httpCommon.get(`shopify/shop/`).then((res) => {
  //     console.log("checkout modal getShopifyShopInfo res.data");
  //     console.log(res.data);
  //     return res.data;
  //   });
  // };

  // const testFulfillment = async () => {
  //   console.log("testFulfillment");

  //   // ----- get fulfillment orders from shopify -----

  //   let fulfillment_orders;

  //   await httpCommon
  //     .get(`shopify/fulfillment-orders/${order.id}`)
  //     .then((res) => {
  //       console.log("res");
  //       console.log(res);

  //       fulfillment_orders = res.data;

  //       return res;
  //     })
  //     .catch((err) => {
  //       console.log("error1");
  //       console.log(err);
  //     });

  //   console.log("fulfillment_orders");
  //   console.log(fulfillment_orders);

  //   // const response = httpCommon.put(
  //   //   `shops/${userInfo.shopId}/fulfillment_orders/${order.id}`,
  //   //   fulfillment_orders
  //   // );

  //   // console.log("response2");
  //   // console.log(response);

  //   // return response;

  //   // ----- save fulfillment orders to mongo -----

  //   await httpCommon
  //     .put(
  //       `shops/${userInfo.shopId}/fulfillment_orders/${order.id}`,
  //       fulfillment_orders
  //     )
  //     .then((res) => {
  //       console.log("res");
  //       console.log(res);
  //       return res;
  //     })
  //     .catch((err) => {
  //       console.log("error2");
  //       console.log(err);
  //     });

  //   // ----- create fulfillment (working) -----

  //   // let line_items_by_fulfillment_order = [
  //   //   {
  //   //     fulfillment_order_id: fulfillment_orders[0].id,
  //   //     fulfillment_order_line_items: fulfillment_orders[0].line_items,
  //   //   },
  //   // ];

  //   // let create_fulfillment_response;

  //   // await httpCommon
  //   //   .post(`shopify/create-fulfillment`, line_items_by_fulfillment_order)
  //   //   .then((res) => {
  //   //     console.log("res");
  //   //     console.log(res);

  //   //     create_fulfillment_response = res.data.fulfillment;

  //   //     return res;
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log("error3");
  //   //     console.log(err);
  //   //   });

  //   // console.log("create_fulfillment_response");
  //   // console.log(create_fulfillment_response);

  //   // ------ update tracking info
  //   let fulfillments;

  //   await httpCommon
  //     .get(`shopify/fulfillments/${fulfillment_orders[0].id}`)
  //     .then((res) => {
  //       console.log("res");
  //       console.log(res);

  //       fulfillments = res.data.fulfillments;

  //       return res;
  //     })
  //     .catch((err) => {
  //       console.log("error1");
  //       console.log(err);
  //     });

  //   console.log("fulfillments");
  //   console.log(fulfillments);

  //   const tracking_info = {
  //     company: "FedEx",
  //     number: "123456789012",
  //     url: "https://www.fedex.com/fedextrack/?trknbr=123456789012&trkqual=12023~123456789012~FDEG",
  //   };

  //   let fulfillment_response;
  //   const fulfillmentId = fulfillments[0].id;

  //   await httpCommon
  //     .post(
  //       `shopify/update-fulfillment-tracking/${fulfillmentId}`,
  //       tracking_info
  //     )
  //     .then((res) => {
  //       console.log("res");
  //       console.log(res);

  //       fulfillment_response = res.data;

  //       return res;
  //     })
  //     .catch((err) => {
  //       console.log("error1");
  //       console.log(err);
  //     });

  //   console.log("fulfillment_response");
  //   console.log(fulfillment_response);

  //   //----- get fulfillments
  //   //let fulfillments;

  //   await httpCommon
  //     .get(`shopify/fulfillments/${fulfillment_orders[0].id}`)
  //     .then((res) => {
  //       console.log("res");
  //       console.log(res);

  //       fulfillments = res.data.fulfillments;

  //       return res;
  //     })
  //     .catch((err) => {
  //       console.log("error1");
  //       console.log(err);
  //     });

  //   console.log("fulfillments");
  //   console.log(fulfillments);

  //   await httpCommon
  //     .put(`shops/${userInfo.shopId}/fulfillments/${order.id}`, fulfillments)
  //     .then((res) => {
  //       console.log("res");
  //       console.log(res);
  //       return res;
  //     })
  //     .catch((err) => {
  //       console.log("error2");
  //       console.log(err);
  //     });

  //   // ----- move fulfillment orders to jemmity service -----

  //   // let fulfillment_services;

  //   // await httpCommon
  //   //   .get(`shopify/fulfillment-services/`)
  //   //   .then((res) => {
  //   //     console.log("res");
  //   //     console.log(res);

  //   //     fulfillment_services = res.data.fulfillment_services;

  //   //     return res;
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log("error get fulfillment services");
  //   //     console.log(err);
  //   //   });

  //   // let jemmity_fulfillment_service = fulfillment_services.find(
  //   //   (o) => o.name === "Jemmity"
  //   // );

  //   // const move_fulfillment_order = {
  //   //   fulfillment_order: {
  //   //     new_location_id: jemmity_fulfillment_service.location_id,
  //   //     fulfillment_order_line_items: fulfillment_orders[0].line_items,
  //   //   },
  //   // };

  //   // let move_response;

  //   // await httpCommon
  //   //   .post(
  //   //     `shopify/move-fulfillment-order/${fulfillment_orders[0].id}`,
  //   //     move_fulfillment_order
  //   //   )
  //   //   .then((res) => {
  //   //     console.log("res");
  //   //     console.log(res);

  //   //     move_response = res.data;

  //   //     return res;
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log("error3");
  //   //     console.log(err);
  //   //   });

  //   // console.log("move_response");
  //   // console.log(move_response);

  //   // await httpCommon
  //   //   .put(
  //   //     `shops/${userInfo.shopId}/move_fulfillment/${order.id}`,
  //   //     move_response
  //   //   )
  //   //   .then((res) => {
  //   //     console.log("res5");
  //   //     console.log(res);
  //   //     return res;
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log("error5");
  //   //     console.log(err);
  //   //   });

  //   // ----- create fulfillment (working) -----

  //   // let line_items_by_fulfillment_order = [
  //   //   {
  //   //     fulfillment_order_id: fulfillment_orders[0].id,
  //   //     fulfillment_order_line_items: fulfillment_orders[0].line_items,
  //   //   },
  //   // ];

  //   // let create_fulfillment_response;

  //   // await httpCommon
  //   //   .post(`shopify/create-fulfillment`, line_items_by_fulfillment_order)
  //   //   .then((res) => {
  //   //     console.log("res");
  //   //     console.log(res);

  //   //     create_fulfillment_response = res.data.fulfillment;

  //   //     return res;
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log("error3");
  //   //     console.log(err);
  //   //   });

  //   // console.log("create_fulfillment_response");
  //   // console.log(create_fulfillment_response);

  //   // await httpCommon
  //   //   .put(
  //   //     `shops/${userInfo.shopId}/fulfillment/${order.id}`,
  //   //     create_fulfillment_response
  //   //   )
  //   //   .then((res) => {
  //   //     console.log("res");
  //   //     console.log(res);
  //   //     return res;
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log("error4");
  //   //     console.log(err);
  //   //   });

  //   // ----- send fulfillment request -----

  //   // console.log("----- send fulfillment request -----");

  //   // const fulfillmentOrderId = fulfillment_orders[0].id;
  //   // const fulfillment_order_line_items = fulfillment_orders[0].line_items;

  //   // console.log("fulfillmentOrderId = " + fulfillmentOrderId);
  //   // console.log("fulfillment_order_line_items");
  //   // console.log(fulfillment_order_line_items);

  //   // let fulfillment_request_response;

  //   // await httpCommon
  //   //   .post(
  //   //     `shopify/send-fulfillment-request/${fulfillmentOrderId}`,
  //   //     fulfillment_order_line_items
  //   //   )
  //   //   .then((res) => {
  //   //     console.log("res");
  //   //     console.log(res);

  //   //     fulfillment_request_response = res.data;

  //   //     return res;
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log("error3");
  //   //     console.log(err);
  //   //   });

  //   // console.log("fulfillment_request_response");
  //   // console.log(fulfillment_request_response);

  //   // await httpCommon
  //   //   .put(
  //   //     `shops/${userInfo.shopId}/fulfillment_request/${order.id}`,
  //   //     fulfillment_request_response
  //   //   )
  //   //   .then((res) => {
  //   //     console.log("res");
  //   //     console.log(res);
  //   //     return res;
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log("error4");
  //   //     console.log(err);
  //   //   });
  // };

  // const testClick = async () => {
  //   console.log("testClick");

  //   const mysql_shop = await getShop();
  //   console.log("captureOrder mysql_shop = ");
  //   console.log(mysql_shop);
  //   const mysql_shop_id = mysql_shop.shop_id;
  //   console.log("captureOrder mysql_shop_id = " + mysql_shop_id);

  //   const payment = await addOrderPayment(mysql_shop_id, 0);
  //   console.log(payment);

  //   // redirect to success/receipt page
  //   console.log("need to redirect to success/receipt page");
  //   //actions.redirect(`orders/payment/success/${payment.payment_ref_number}`);
  //   //actions.redirect(`orders/payment/success/`);
  // };

  const getShop = async () => {
    await httpCommon.post(`orders/addshop/`, {
      mongoShopObjectID: userInfo.shopId,
      shopName: userInfo.shop_name,
    });

    let response = await httpCommon
      .get(`orders/shop/${userInfo.shopId}`)
      .then((res) => {
        return res.data;
      });
    // console.log("response.data");
    // console.log(response);

    return response;
  };

  const addOrderPayment = async (mysql_shop_id, paypalDetails) => {
    await httpCommon.post(`orders/addorderpayment/`, {
      shop_id: mysql_shop_id,
      shopify_order_id: order.id,
      shopify_order_number: order.order_number,
      subtotal: subTotal,
      tax: tax,
      total: -1,
      payment_date: "2022-05-14",
      payment_ref_number: "-1",
      paypal_order_id: "-1",
      paypal_order_object: JSON.stringify(paypalDetails),
    });

    let response = await httpCommon
      .get(`orders/orderpayment/${order.order_number}`)
      .then((res) => {
        return res.data;
      });

    // console.log("addOrderPayment");
    // console.log(response);

    let payment_id;
    if (response.length === 0) {
      payment_id = -1;
    } else {
      payment_id = response.payment_id;
    }

    //console.log("payment_id = " + payment_id);

    return response;
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          Checkout Order #
          {order !== null && order !== undefined ? order.order_number : null}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {order !== null && order !== undefined ? (
          <CheckoutModalBodyContainer className="row order-table m-2">
            <table className="table">
              <thead>
                <tr>
                  <th>Images</th>
                  <th>Product</th>
                  <th>Supplier</th>
                  <th>Quantity</th>
                  <th className="price">Customer Cost</th>
                  {/* <th>Status</th> */}
                  <th className="price cost-col">Your Cost</th>
                </tr>
              </thead>
              <tbody>
                {order.line_items.map((item) => {
                  return item.product_id !== null && item.jemmity_data ? (
                    <tr key={item.id}>
                      <td>
                        <img src="https://via.placeholder.com/50" fluid />
                      </td>
                      <td>{item.name}</td>
                      <td>{item.vendor || "N/A"}</td>
                      <td>{item.quantity}</td>
                      <td className="price">
                        ${Number(item.price).toFixed(2)} CAD
                      </td>
                      <td className="price cost-col">
                        ${Number(item.vendor_price).toFixed(2)} CAD
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <div className="alert alert-warning pt-2 pb-2">
                          Product deleted / Product not from Jemmity
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-between align-items-start">
              <table style={{ width: "50%" }}>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Shipping Address:</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "700" }}>
                      <div
                        style={{
                          backgroundColor: "#ddd",
                          padding: "5px",
                          margin: "5px",
                        }}
                      >
                        {order.customer.first_name} {order.customer.last_name}
                        <br />
                        {order.shipping_address.address1}
                        <br />
                        {order.shipping_address.address2 !== "" ? (
                          <Fragment>
                            {order.shipping_address.address2}
                            <br />
                          </Fragment>
                        ) : null}
                        {order.shipping_address.city}{" "}
                        {order.shipping_address.province_code}{" "}
                        {order.shipping_address.zip}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontStyle: "italic", color: "red" }}>
                      Please note that we are only delivering to Canadian
                      addresses at this point. If the address above is
                      incorrect, please correct it in your Shopify Orders page.
                    </td>
                  </tr>
                </tbody>
              </table>
              <table>
                <tbody>
                  <tr className="fw-bold">
                    <td className="text-end">Sub-total:</td>
                    <td className="text-end">
                      ${Number(subTotal).toFixed(2)} CAD
                    </td>
                  </tr>
                  <tr>
                    <td className="text-end border-top">Shipping country:</td>
                    <td className="text-end border-top">
                      {order.shipping_address
                        ? order.shipping_address.country
                        : "N/A"}
                    </td>
                  </tr>
                  {/*<tr>
                    <td className="text-end">Shipping method:</td>
                    <td>
                      <select
                        className="form-select form-select-sm d-flex"
                        style={{ width: "120px" }}
                      >
                        <option value={1} selected>
                          Standard
                        </option>
                        <option value={2}>Express</option>
                      </select>
                    </td>
                      </tr>*/}
                  <tr>
                    <td className="text-end">Shipping price:</td>
                    <td className="text-end">
                      ${Number(shippingPrice).toFixed(2)} CAD
                    </td>
                  </tr>
                  {/*<tr>
                    <td className="text-end">Tax:</td>
                    <td className="text-end">${Number(tax).toFixed(2)} CAD</td>
                      </tr>*/}
                  <tr className="fw-bold">
                    <td className="text-end border-top">Total price:</td>
                    <td
                      className="text-end border-top"
                      style={{ color: "orangered" }}
                    >
                      ${Number(total).toFixed(2)} CAD
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CheckoutModalBodyContainer>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 d-flex justify-content-between">
          <div>&nbsp;</div>
          <div>
            {/*<button
              className="btn btn-success font-weight-bold"
              style={{ backgroundColor: "#6BC300", borderColor: "#6BC300" }}
            >
              Shopify Payment
            </button>*/}
            {/*<button className="btn btn-warning text-primary font-weight-bold ml-2">
              PayPal
          </button>*/}
            {/*<PayPalButtons
              style={paypalStyle}
              forceReRender={[total, currency, paypalStyle]}
              createOrder={createPaypalOrder}
              onApprove={onPaypalApprove}
              onError={onPaypalError}
            />*/}
            {/*<PayPalButton
              orderTotal={total}
              description={`Jemmity Order ${
                order !== null && order !== undefined ? order.order_number : ""
              }`}
              lineItems={
                order !== null && order !== undefined ? order.line_items : null
              }
              subTotal={subTotal}
              tax={tax}
              shippingCost={shippingPrice}
            />*/}
            <PayPalButton2
              order={order}
              orderTotal={total}
              description={`Jemmity Order ${
                order !== null && order !== undefined ? order.order_number : ""
              }`}
              lineItems={
                order !== null && order !== undefined ? order.line_items : null
              }
              subTotal={subTotal}
              tax={tax}
              shippingCost={shippingPrice}
              userInfo={userInfo}
            />
            {/*<button
              className="btn btn-primary"
              type="button"
              onClick={(e) => testClick()}
            >
              Test Button
            </button>
            <button
              className="btn btn-primary"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                testFulfillment();
              }}
            >
              Test Button Fulfillment Stuff
            </button>*/}
          </div>
          <div>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CheckoutModal;
