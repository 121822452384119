import React, { useEffect, useState } from "react";
import httpCommon from "../../http-common";

const HomeStatItem = (props) => {
  const { userCtx, text, iconClass, iconColor } = props;
  const { userInfo } = userCtx;
  const [total, setTotal] = useState("");

  useEffect(() => {
    // console.log("text.toLowerCase() = " + text.toLowerCase());

    if (text.toLowerCase().includes("imported")) {
      // httpCommon.get(`shops/${userInfo.shopId}`).then((res) => {
      //   setTotal(res.data.shopify_product_list.length);
      // });

      httpCommon.get(`shopify/products`).then((res) => {
        setTotal(res.data.products.length);
      });

      // this API call below isn't working for some reason and returning 400 error
      //   httpCommon
      //     .get(`/shops/${userInfo.shopId}/shopify_product_list`)
      //     .then((res) => {
      //       console.log("res");
      //       console.log(res);
      //       return res;
      //     })
      //     .then((res) => {
      //       console.log("home-stat-item res.data");
      //       console.log(res.data);
      //       setTotal(res.data.length);
      //     })
      //     .catch((err) => {
      //       console.error(err);
      //     });
    } else if (text.toLowerCase().includes("orders")) {
      //console.log("get home current month orders stuff");
      //setTotal("N/A");
      httpCommon
        .get(`shops/${userInfo.shopId}/current_month_orders`)
        .then((res) => {
          setTotal(res.data.length);
        });
    } else if (text.toLowerCase().includes("plan")) {
      httpCommon.get(`shops/${userInfo.shopId}/subscription`).then((res) => {
        // console.log("home component shop subscription useEffect");
        // console.log(res);

        //setShopSubscription(res.data.recurring_application_charge);
        if (
          res.data !== null &&
          res.data.hasOwnProperty("recurring_application_charge") &&
          res.data.recurring_application_charge.status === "active"
        ) {
          setTotal(res.data.recurring_application_charge.name);
        } else {
          setTotal("Not subscribed");
        }
      });
    }
  }, []);

  return (
    <div className="card">
      <div className="card-content">
        <div className="card-body">
          <div className="media d-flex justify-content-between">
            <div className="align-self-center">
              <i
                className={iconClass}
                style={{ fontSize: "3rem", color: `${iconColor}` }}
              ></i>
            </div>
            <div className="media-body text-end">
              <h3>{total}</h3>
              <span>{text}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeStatItem;
