import React from "react";

import { Modal } from "react-bootstrap";

const ErrorModal = ({
    content = {
        header: '',
        text: '',
    },
    show,
    closeCallback
}) => {

    return (
        <Modal show={show} size='sm'>
            <Modal.Header closeButton>
                <div>

                    {content.header.toString()}
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {content.text.toString()}

                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-secondary' onClick={closeCallback}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default ErrorModal;