import React, { useState, useEffect } from "react";
import { ImportItem } from "../import-item/import-item.component";

import httpCommon from "../../http-common";

export const ImportCollection = (props) => {
  const { userInfo, setUserInfo } = props.userCtx;
  const { IsMaxProductsImported } = props;

  const [imports, setImports] = useState([]);

  const [collections, setCollections] = useState([]);

  const deleteImportByProductId = (pid) => {
    const newImports = imports.filter((i) => {
      return i._id !== pid;
    });
    setImports(newImports);
  };

  useEffect(() => {
    httpCommon
      .get("/shops/" + userInfo.shopId + "/imports")
      .then((res) => {
        return res;
      })
      .then((res) => {
        const loadedImports = res.data;
        setImports(loadedImports);
        // console.log("loaded imports");
        // console.log(loadedImports);
      })
      .catch((err) => {
        // console.error(err);
      });

    httpCommon
      .get("/shopify/collections")
      .then((res) => {
        const collections = res.data;
        setCollections(collections);
        // console.log("loaded collections");
      })
      .catch((err) => {
        // console.error(err);
      });
  }, []);

  return (
    <div
      className=""
      style={{
        padding: "30px",
        //"background-color": "pink"
      }}
    >
      {imports === []
        ? "loading..."
        : imports.map((element, index) => {
            return (
              <ImportItem
                userCtx={props.userCtx}
                deleteImportByProductId={deleteImportByProductId}
                key={element._id}
                index={index}
                importData={element}
                collections={collections}
                IsMaxProductsImported={IsMaxProductsImported}
              />
            );
          })}
    </div>
  );
};
