import React, { useState } from "react";

import { ImportItemContainer } from "./import-item.styles";
import httpCommon from "../../http-common";

//Bootstrap dependencies

//Sub components
import { ProductPane } from "./panes/product-pane.component";
import { DescriptionPane } from "./panes/description-pane.component";
import { ImagesPane } from "./panes/images-pane.component";
import { VariantPane } from "./panes/variant-pane.component";

// TODO:
// - change button divors and button sizes as well as icon location/inclusion until find something that looks better

export const ImportItem = (props) => {
  const { importData } = props;
  const [importState, setImportState] = useState({
    ...importData,
  });

  const updateImportState = (stateUpdate) => {
    setImportState({
      ...importState,
      ...stateUpdate,
    });
  };

  //const [checkedImages, setCheckedImages] = useState([]);

  const { userInfo, setUserInfo } = props.userCtx;

  const { title } = importState;

  const { deleteImportByProductId } = props;

  const { collections } = props;

  const { index } = props;

  const { IsMaxProductsImported } = props;

  const removeImport = () => {
    httpCommon
      .delete(`shops/${userInfo.shopId}/imports/${importData._id}`)
      .then((res) => {
        //console.log(res);
        return res;
      })
      .then((res) => {
        // console.log("setting user info");
        setUserInfo({
          ...userInfo,
          importIds: userInfo.importIds.filter((id) => id !== props._id),
        });
        return res;
      })
      .then((res) => {
        // console.log("deleting import from import list");
        deleteImportByProductId(importData._id);
      })
      .catch((e) => {
        // console.error("something went wrong" + e);
      });
  };

  const save = async () => {
    // console.log("save.. checked images");
    // console.log(checkedImages);
    await httpCommon.put(`shops/${userInfo.shopId}/imports/${importData._id}`, {
      ...importState,
    });
  };

  return (
    <div className="">
      <ImportItemContainer className="card shadow-sm p-0 rounded-0">
        <div className="row m-0">
          <div className="col-3 d-flex align-items-center">
            <img
              src={
                importState.images.length > 0
                  ? importState.images[0].src
                  : "https://via.placeholder.com/150"
              }
              alt="Thumbnail"
              style={{ width: "100%" }}
            />
          </div>

          <div className="col-9 pt-2">
            <div className="d-flex justify-content-between">
              <h4 className="card-title">{title}</h4>
              <div>
                <button
                  className={`btn btn-primary me-2 btn-import ${
                    IsMaxProductsImported ? "disabled" : ""
                  }`}
                  onClick={async () => {
                    await save();
                    httpCommon
                      .post(`shopify/products/${importData._id}`, importData.selected_images)
                      .then((res) => {
                        // console.log(res);
                        removeImport();
                      });
                  }}
                >
                  Import
                </button>

                <button
                  className={`btn btn-info me-2 text-white btn-save ${
                    IsMaxProductsImported ? "disabled" : ""
                  }`}
                  onClick={async () => {
                    await save();
                  }}
                >
                  Save
                </button>

                <button
                  className="btn btn-light btn-outline-secondary"
                  onClick={() => {
                    removeImport();
                  }}
                  style={{ borderColor: "#ddd" }}
                >
                  Remove
                </button>
              </div>
            </div>
            <div className="row ps-2">
              <div
                className="nav flex-row nav-tabs align-self-center"
                id={`v-pills-tab-${index}`}
                role="tablist"
                aria-orientation="vertical"
              >
                <a
                  className="nav-link active"
                  id={`v-pills-product-tab-${index}`}
                  data-toggle="pill"
                  href={`#v-pills-product-${index}`}
                  role="tab"
                  aria-controls={`#v-pills-product-${index}`}
                  aria-selected="true"
                >
                  Product
                </a>

                <a
                  className="nav-link"
                  id={`v-pills-variants-tab-${index}`}
                  data-toggle="pill"
                  href={`#v-pills-variants-${index}`}
                  role="tab"
                  aria-controls={`v-pills-variants-${index}`}
                  aria-selected="false"
                >
                  Variants
                </a>

                <a
                  className="nav-link"
                  id={`v-pills-description-tab-${index}`}
                  data-toggle="pill"
                  href={`#v-pills-description-${index}`}
                  role="tab"
                  aria-controls={`v-pills-description-${index}`}
                  aria-selected="false"
                >
                  Description
                </a>

                <a
                  className="nav-link"
                  id={`v-pills-images-tab-${index}`}
                  data-toggle="pill"
                  href={`#v-pills-images-${index}`}
                  role="tab"
                  aria-controls={`v-pills-images-${index}`}
                  aria-selected="false"
                >
                  Images
                </a>
              </div>
            </div>
            <div className="row body bg-light">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane product-pane fade show active"
                  id={`v-pills-product-${index}`}
                  role="tabpanel"
                  aria-labelledby={`v-pills-product-tab-${index}`}
                >
                  <ProductPane
                    importState={importState}
                    updateImportState={updateImportState}
                    collections={collections}
                  />
                </div>

                <div
                  className="tab-pane variants-pane fade"
                  id={`v-pills-variants-${index}`}
                  role="tabpanel"
                  aria-labelledby={`v-pills-variants-tab-${index}`}
                >
                  <VariantPane
                    variants={importState.variants}
                    updateImportState={updateImportState}
                  />
                </div>

                <div
                  className="tab-pane fade"
                  id={`v-pills-description-${index}`}
                  role="tabpanel"
                  aria-labelledby={`v-pills-description-tab-${index}`}
                >
                  <DescriptionPane
                    description={importState.body_html}
                    updateImportState={updateImportState}
                  />
                </div>

                <div
                  className="tab-pane fade"
                  id={`v-pills-images-${index}`}
                  role="tabpanel"
                  aria-labelledby={`v-pills-images-tab-${index}`}
                >
                  <ImagesPane
                    title={importState.title}
                    images={importState.images}
                    checkedImages={importState.selected_images}
                    //setCheckedImages={setCheckedImages}
                    updateImportState={updateImportState}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col col-2 align-self-center "></div>
        </div>
      </ImportItemContainer>
    </div>
  );
};
