
//handles axios errors being intercepted based on type and statusCode
const interpretAxiosErrors = (error) => {
    //console.log('axios error intercepted');
    let newErrorState = {
        showModal: false,
        content: {
            header: '',
            text: ''
        }
    };

    newErrorState.showModal = true;
    newErrorState.content.header = "An error has occurred";
    //there was a response but bad statusCode
    if (error.response) {

        newErrorState.content.text = error.response.data.error;

        switch (error.response.status) {
            case 311:
                newErrorState.content.header = 'Authentication Error';
                break;
        };
        // console.log('data')
        // console.log(error.response.data);
        // console.log('status')
        // console.log(error.response.status);
        // console.log('headers')
        // console.log(error.response.headers);
        // setIsError(true);
    }
    //request made but no response
    else if (error.request) {
        newErrorState.content.text = 'The request was sent but no response was received from the server. Please try again.'
    }
    //else malformed request/something else went wrong
    else {
        newErrorState.content.text = "There was an error sending your request to the server. Please try again."
    }

    return newErrorState;
}

export default interpretAxiosErrors;