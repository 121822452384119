import { useState } from 'react';

function Login() {

    const [storeName, setStoreName] = useState('');
    const [warning, setWarning] = useState('');


    return (
        <div>

            <h1>Connect your Shopify Store to Login</h1>
            {
                warning !== '' ? <div className="warning">{warning}</div> : null
            }
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    const storeRegex = /^([\w-]+)\.myshopify\.com/i;
                    try {
                        const storeRgxRes = storeRegex.exec(storeName);
                        if (storeRgxRes.length === 2) {
                            window.location.href = 'https://app.jemmity.com/auth/install?shop=' + storeName;
                        }
                    } catch (e) {

                        // console.log(e);
                        setWarning('store name must be of the format \'*your store*\'.myshopify.com');
                    }
                }}
            >
                <input value={storeName} type='text' placeholder="Enter your Shopify Store Name"
                    onChange={e => {
                        setStoreName(e.target.value);
                    }}
                />
                <button type='submit'>Login with Shopify</button>
            </form>



        </div>
    )
};


export default Login;