import React, { useState, useEffect } from "react";
import { UserContext } from "../../App";
import httpCommon from "../../http-common";
import { Form, Row, Col, Accordion } from "react-bootstrap";

export const SupportPage = () => {
  const [shopId, setShopId] = useState(null);
  const [shopData, setShopData] = useState(null);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [validated, setValidated] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  useEffect(() => {
    // console.log("useEffect");

    if (shopId !== null) {
      httpCommon.get(`shops/${shopId}/shopify_shop_data`).then((res) => {
        // console.log("shopify_shop_data");
        // console.log(res.data);

        setShopData(res.data);
      });
    }
  }, [shopId]);

  useEffect(() => {
    // console.log("messageSent");
    // console.log(messageSent);
    // console.log("validated");
    // console.log(validated);
  }, [messageSent]);

  const handleSubmit = (event) => {
    event.preventDefault();

    // console.log("subject");
    // console.log(subject);
    // console.log("message");
    // console.log(message);

    const form = event.currentTarget;
    const isValid = form.checkValidity();

    if (isValid === false) {
      event.stopPropagation();

      // console.log("form invalid");
    } else {
      // console.log("form valid");
      sendMessage();
    }

    setValidated(true);
  };

  const sendMessage = async () => {
    // console.log("sendMessage");
    // console.log(message);

    let txtAreaHtml = message;
    txtAreaHtml = txtAreaHtml.replace(/\n/g, "<br />");

    // console.log("txtAreaHtml");
    // console.log(txtAreaHtml);

    const emailBody = `
    <p>
    Store Name: <strong>${shopData.name}</strong><br />
    Email: <strong>${shopData.email}</strong><br />
    Shop owner: <strong>${shopData.shop_owner}</strong>
    </p>
    <p>
    <u>Message</u>:<br />
    ${txtAreaHtml}
    </p>
    `;

    const emailSubject = "[Jemmity Support Request] " + subject;

    await httpCommon.post(`shops/${shopId}/support_messages`, {
      recipient: shopData.email,
      subject: emailSubject,
      message: message,
      emailBody: emailBody,
    });

    setSubject("");
    setMessage("");
    setValidated(false);
    setMessageSent(true);
  };

  return (
    <div>
      <div
        className="sticky-md-top"
        style={{
          backgroundColor: "#f3f3f3",
          boxShadow: "0 .5rem 1rem -.5rem rgba(0,0,0,.15)",
        }}
      >
        <div className="row">
          <div className="col-12 mt-3 mb-1 ms-3">
            <h4 className="text-uppercase">Support</h4>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <h4>Common Questions</h4>

        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              How can I start selling your items in my store?
            </Accordion.Header>
            <Accordion.Body>
              <ol>
                <li className="mb-3">
                  <p>
                    Go to the <a href="/search-products">Search Products</a>{" "}
                    page. Browse through the items you like by using the search
                    bar at the top, the filter categories, or just by scrolling
                    through the products.
                  </p>
                  <p>
                    You can click on each product to see more details. You can
                    use our shipping estimate tool by selecting the shipping
                    country (at this point, we are only shipping within Canada).
                  </p>
                </li>
                <li className="mb-3">
                  When you've found a product you want to sell, click the "Add
                  to Import List" button on the product box, or in the product
                  details window.
                </li>
                <li>
                  After that, navigate to your{" "}
                  <a href="/imports">Import List</a>. You can edit each item in
                  page, click Save, and then click Import which will bring the
                  product into your store product listing. You can also Import
                  the product right away, and edit the details in your Shopify
                  store Products page.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              After I receive an order containing a Jemmity product, what do I
              do next?
            </Accordion.Header>
            <Accordion.Body>
              <ol>
                <li className="mb-3">
                  Please go to your <a href="/orders">My Orders</a> page and you
                  should see the order listed there. Click on the Check Out
                  button.
                </li>
                <li className="mb-3">
                  Please review the items listed and the shipping address and
                  then click on the PayPal button. You'll need to sign into your
                  PayPal account and pay for the item and shipping.
                </li>
                <li>
                  Once you have paid for your item, we will begin processing
                  your order. When the item is shipped, the fulfillment status
                  for the order will be updated along with a tracking number,
                  which will be available to you in your Shopify store Orders
                  page.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <h4 className="mt-5">Contact Us</h4>
        <p>
          If you have any questions or require any assistance, please contact us
          using the form below. Please do not include any confidential
          information in your message (e.g., credit card information). A copy of
          your message will be sent to the email listed for your Shopify store
          account
          {shopData !== null ? (
            <span className="fw-bold ms-1">({shopData.email})</span>
          ) : null}
          .
        </p>
        <p>
          You can also email us directly at{" "}
          <a href="mailto:support@jemmity.com">support@jemmity.com</a>.
        </p>
        <p>Our typical response rate is between 1-3 business days.</p>

        <UserContext.Consumer>
          {(userCtx) => (
            <div className="mt-4">
              {setShopId(userCtx.userInfo.shopId)}
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Label className="fw-bold">Subject</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      //defaultValue={subject}
                      value={subject}
                      onChange={(e) => {
                        setSubject(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a subject.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col} md="12" controlId="validationCustom02">
                    <Form.Label className="fw-bold">Message</Form.Label>
                    <div className="bg-white mb-1 border p-2">
                      <u>Store Information</u>{" "}
                      <small className="fst-italic">
                        (will automatically be included in your message)
                      </small>
                      <br />
                      Store Name:{" "}
                      <strong>{shopData ? shopData.name : null}</strong>
                      <br />
                      Email: <strong>{shopData ? shopData.email : null}</strong>
                      <br />
                      Shop owner:{" "}
                      <strong>{shopData ? shopData.shop_owner : null}</strong>
                    </div>
                    <Form.Control
                      required
                      as="textarea"
                      rows={10}
                      placeholder=""
                      //defaultValue={message}
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a message.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <button className="btn btn-primary" type="submit">
                  Send
                </button>
              </Form>
            </div>
          )}
        </UserContext.Consumer>

        {messageSent ? (
          <div className="alert alert-success mt-4">
            Your message has been sent.
          </div>
        ) : null}
      </div>
    </div>
  );
};
