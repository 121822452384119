import { UserContext } from "../../App";
import React, { Fragment, useEffect, useState } from "react";
import { ImportCollection } from "../../components/import-collection/import-collection.component";
import httpCommon from "../../http-common";

export const Imports = () => {
  const [shopId, setShopId] = useState(null);
  const [maxProductsImportedAllowed, setMaxProductsImportedAllowed] =
    useState(null);
  const [IsMaxProductsImported, setIsMaxProductsImported] = useState(false);

  useEffect(() => {
    checkMaxProductsImported();
  }, [shopId]);

  const checkMaxProductsImported = () => {
    if (shopId) {
      //console.log("checkMaxProductsImported... ");

      httpCommon
        .get("/shopify/products/")
        .then((res) => {
          return res;
        })
        .then((res) => {
          const numProductsImported = res.data.products.length;
          //console.log("numProductsImported = " + numProductsImported);

          httpCommon.get(`subscriptions/shop/${shopId}`).then((res) => {
            const maxProductsSourcedAllowed = res.data[0].max_products_sourced;
            setMaxProductsImportedAllowed(maxProductsSourcedAllowed);

            // console.log(
            //   "maxProductsSourcedAllowed = " + maxProductsSourcedAllowed
            // );

            if (numProductsImported >= maxProductsSourcedAllowed) {
              setIsMaxProductsImported(true);
            }
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  //TODO
  //PAGINATION
  return (
    <div>
      <div
        className="sticky-md-top"
        style={{
          backgroundColor: "#f3f3f3",
          boxShadow: "0 .5rem 1rem -.5rem rgba(0,0,0,.15)",
        }}
      >
        <div className="row">
          <div className="col-12 mt-3 mb-1 ms-3">
            <h4 className="text-uppercase">Import List</h4>
          </div>
        </div>
      </div>
      {IsMaxProductsImported ? (
        <div class="alert alert-warning fw-bold fst-italic m-3">
          You have imported the maximum allowed products of{" "}
          {maxProductsImportedAllowed} products from your current subscription
          plan. Please either remove some of our products from your product
          listing or upgrade your subscription (if applicable).
        </div>
      ) : null}
      <p className="m-3">We will inform you if any products are out of stock.</p>
      <div>
        <UserContext.Consumer>
          {(value) => (
            <Fragment>
              {setShopId(value.userInfo.shopId)}
              <ImportCollection
                userCtx={value}
                IsMaxProductsImported={IsMaxProductsImported}
              />
            </Fragment>
          )}
        </UserContext.Consumer>
      </div>
    </div>
  );
};
