import React, { useEffect, useState } from "react";
import httpCommon from "../../http-common";
import { SearchTextBox } from "./search-bar.styles";

const SearchBar = ({
  displayCategoryDdl,
  categoryFilter,
  subcategoryFilter,
  updateCategoryFilter,
  updateSubcategoryFilter,
  updateProductsFiltered,
  updateRebindAllProducts,
}) => {
  const [categories, setCategories] = useState([]);
  const [displaySubcatDdl, setDisplaySubcatDdl] = useState(false);
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    // console.log("search-bar useEffect 1");

    if (categories.length === 0) {
      // console.log("categories.length === 0");

      httpCommon
        .get("/categories/")
        .then((res) => {
          return res;
        })
        .then((res) => {
          return setCategories(res.data);
        })
        .catch((err) => {
          // console.error(err);
        });
    }

    if (!displayCategoryDdl) {
      // console.log("setDisplaySubcatDdl(false)");
      setDisplaySubcatDdl(false);
    } else if (categoryFilter !== "") {
      // console.log("setDisplaySubcatDdl(true)");
      setDisplaySubcatDdl(true);
    }
  }, [displayCategoryDdl, displaySubcatDdl]);

  useEffect(() => {
    // console.log("search-bar useEffect 2");

    if (categoryFilter !== "") {
      // console.log("SearchBar subcategoryFilter = " + subcategoryFilter);
      handleCategoryChange(categoryFilter);
    }
  }, [categoryFilter]);

  const handleCategoryChange = (categoryName) => {
    if (categoryName !== "") {
      const subcat = categories.find(
        (x) => x.name === categoryName
      ).subcategories;

      // console.log("handleCategoryChange 1");

      setSubCategories(subcat);
      setDisplaySubcatDdl(true);
    } else {
      // console.log("handleCategoryChange 2");

      setSubCategories([]);
      setDisplaySubcatDdl(false);
    }

    updateRebindAllProducts(true);
    updateSubcategoryFilter("");
    //setSelectedCategory(categoryName);
    updateCategoryFilter(categoryName);
    updateProductsFiltered(false);
  };

  const handleSubcategoryChange = (subcategoryName) => {
    updateSubcategoryFilter(subcategoryName);
    updateProductsFiltered(false);
  };

  return (
    <div>
      <div className="">
        <div className="d-flex align-items-center justify-content-between">
          <SearchTextBox>
            <div className="search">
              <i className="fas fa-search"></i>
              <input
                type="text"
                className="form-control"
                placeholder="Search title/description"
              />
            </div>
          </SearchTextBox>

          <button type="button" className="btn btn-info ms-2 me-2">
            Search
          </button>

          <select
            className="form-select"
            style={{ width: "inherit", display: "none" }}
          >
            <option>Sort By</option>
            <option value="1">Relevance</option>
            <option value="2">Price low to high</option>
            <option value="3">Price high to low</option>
            <option value="4">Date imported (newest)</option>
            <option value="5">Date imported (oldest)</option>
          </select>

          <select
            className="form-select"
            style={{
              width: "inherit",
              display: displayCategoryDdl ? "inherit" : "none",
            }}
            onChange={(e) => handleCategoryChange(e.target.value)}
            value={categoryFilter}
          >
            <option value="">Categories</option>
            {categories.map((cat) => (
              <option value={cat.name}>{cat.name}</option>
            ))}
          </select>

          <select
            className="form-select"
            style={{
              width: "inherit",
              display: displaySubcatDdl ? "inherit" : "none",
            }}
            onChange={(e) => handleSubcategoryChange(e.target.value)}
            value={subcategoryFilter}
          >
            <option value="">Subcategory</option>
            {subCategories.map((cat) => (
              <option value={cat.name}>{cat.name}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
