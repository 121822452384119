//placeholder

export const ImagesPane = (props) => {
  const { title, images, checkedImages, updateImportState } = props;

  const updateCheckedImages = (img, checked) => {
    // console.log("updateCheckedImages " + img.id + " " + checked);

    if (checked) {
      if (
        checkedImages !== undefined ||
        checkedImages !== null ||
        checkedImages.length === 0
      ) {
        const arr = [];
        arr.push(img);

        updateImportState({
          selected_images: arr,
        });
      }
    } else if (checkedImages.indexOf(img) === -1) {
      const arr = [...checkedImages];
      arr.push(img);

      updateImportState({
        selected_images: arr,
      });
    } else {
      const newArr = checkedImages.filter((object) => {
        return object.id !== img.id;
      });

      updateImportState({
        selected_images: newArr,
      });
    }
  };

  return (
    <div>
      <div className="row mb-3 me-1">
        {images.map((img, index) => (
          <div className="col-3 d-flex justify-content-evenly">
            <div className="ps-2 pe-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="checkboxNoLabel"
                value=""
                aria-label="..."
                onChange={(e) => updateCheckedImages(img, e.target.checked)}
                checked={
                  checkedImages !== undefined &&
                  checkedImages !== null &&
                  checkedImages.length > 0 &&
                  checkedImages.some((x) => {
                    return x.id === img.id;
                  })
                    ? "checked"
                    : null
                }
              />
            </div>
            <img
              src={img.src}
              alt={`${title} ${index + 1}`}
              style={{ width: "90%" }}
            />
          </div>
        ))}
        {/*<div className="col-3 d-flex justify-content-evenly">
          <div className="ps-2 pe-2">
            <input
              className="form-check-input"
              type="checkbox"
              id="checkboxNoLabel"
              value=""
              aria-label="..."
            />
          </div>
          <img
            src="https://i.imgur.com/iP7tRhp.png"
            alt="Placeholder"
            style={{ width: "90%" }}
          />
        </div>
        <div className="col-3 d-flex justify-content-evenly">
          <div className="ps-3 pe-2">
            <input
              className="form-check-input"
              type="checkbox"
              id="checkboxNoLabel"
              value=""
              aria-label="..."
            />
          </div>
          <img
            src="https://i.imgur.com/BsnZ6dv.png"
            alt="Placeholder"
            style={{ width: "90%" }}
          />
        </div>
        <div className="col-3 d-flex justify-content-evenly">
          <div className="ps-2 pe-2">
            <input
              className="form-check-input"
              type="checkbox"
              id="checkboxNoLabel"
              value=""
              aria-label="..."
            />
          </div>
          <img
            src="https://i.imgur.com/iP7tRhp.png"
            alt="Placeholder"
            style={{ width: "90%" }}
          />
        </div>
        <div className="col-3 d-flex justify-content-evenly">
          <div className="ps-3 pe-2">
            <input
              className="form-check-input"
              type="checkbox"
              id="checkboxNoLabel"
              value=""
              aria-label="..."
            />
          </div>
          <img
            src="https://i.imgur.com/BsnZ6dv.png"
            alt="Placeholder"
            style={{ width: "90%" }}
          />
        </div>*/}
      </div>
    </div>
  );
};
