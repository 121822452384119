import styled from "styled-components";
//TODO:
// - left-align all fields (product title etc.)
// - change spacing in product tab (and other labels where applicable) of labels w.r.t. input field: label and field should be more closely grouped
// - change nav tab spacing/location so it is more clear that they are tabs (maybe manually reintroduce the bootstrap navbar styling?)

export const ImportItemContainer = styled.div`
  padding: 10px;
  margin: 5px;
  margin-bottom: 20px;
  border-radius: 8px;
  /* border-color: lightgrey;
    border-style: solid;
    border-width: 1px; */

  .btn-wrapper {
    padding-top: 30px;
    .btn {
      .fas {
      }
      .btn-text {
        text-align: left;
      }
    }
  }
  .header {
    border-style: none solid none none;
    border-width: 1px;
    border-color: lightgrey;
    border-radius: 0px;
  }
  .body {
    padding-top: 10px;
    /* border-style: solid none none none;
    border-width: 1px;
    border-color: lightgrey;
    border-radius: 0px; */
  }
  .card-title {
    font-weight: bold;
  }
  .nav {
    text-align: left;
  }

  .product-pane {
    .form-group {
      font-weight: bold;
      text-align: left;
      margin-bottom: 25px;
    }
  }
  .variants-pane {
    thead {
      text-align: left;
    }
    .price {
      /* text-align: right; */
      white-space: nowrap;

      .form-control {
        text-align: right;
      }
    }
    td {
      padding-right: 10px;
    }
    th {
      padding-right: 10px;
    }
  }
  .pane-nav {
    padding: 12px;
    margin: 5px;
    border-color: blue;
    border-style: solid;
    border-width: 0px;
    border-radius: 10px;

    &[aria-selected="true"] {
      :hover {
      }
      :active {
      }
      :target {
      }
    }
    :focus {
      font-weight: bold;
      border-width: 1px;
      padding: 11px;
    }
    :hover {
      border-width: 1px;
      padding: 11px;
    }
  }
  .nav-tab-container {
    border-width: 0px;
  }

  .nav-link.active {
    background-color: #f8f9fa;
    font-weight: 700;
    border-bottom: 1px solid #f8f9fa;
  }

  .btn-import {
    background-color: #1747b5;
    border-color: #133995;
    font-weight: 700;

    :hover {
      background-color: #133995;
    }
  }

  .btn-save {
    background-color: #45c0ce;
    font-weight: 700;

    :hover {
      background-color: #5ba2be;
    }
  }
`;
