import "./App.css";
import { useEffect, useState, createContext } from "react";
import axios from "axios";

//Pages
import Dashboard from "./pages/dashboard/dashboard.component";
import Login from "./pages/login/login.component";

export const UserContext = createContext();

function App() {
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({
    loggedIn: false,
    shop_name: "",
    shopId: "",
    importIds: [],
    // collections: []
  });

  useEffect(() => {
    axios
      .get("/auth/login", {
        withCredentials: true,
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.status !== 200) {
          setLoading(false);
        } else {
          const { shop_name, shopId, importIds } = res.data;
          setUserInfo({
            loggedIn: true,
            shop_name,
            shopId,
            importIds,
            // collections
          });
          setLoading(false);
          // axios.get('/api/v1/shopify/collections').then((collectionsResponse) => {
          //   const collections = collectionsResponse.data;
          //   // console.log(collections)

          // });
        }
      })
      .catch((err) => {
        if (err.response) {
          // console.error(err.response.data);
          // console.error(err.response.status);
          if (err.response.status === 311) {
            setLoading(false);
          }
        }
      });
  }, []);

  if (loading) {
    return <div>loading....</div>;
  } else {
    if (userInfo.loggedIn) {
      // console.log(userInfo);
      return (
        <UserContext.Provider value={{ userInfo, setUserInfo }}>
          <Dashboard />
        </UserContext.Provider>
      );
    } else {
      return <Login />;
    }
  }
}

export default App;
