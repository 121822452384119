import React, { useState, useEffect } from "react";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import { Popover } from "react-bootstrap";
import httpCommon from "../../http-common";

import { OrderItemContainer } from "./order-item.styles";
import { getShippingPriceWon } from "../../utils/helpers";

export const OrderItem = (props) => {
  const { order, handleShowCheckoutModal, krwRateInfo } = props;
  const { line_items, customer } = order;
  const checkOutVisible = false;

  const [subTotal, setSubTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [shippingPrice, setshippingPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [profit, setProfit] = useState(0);
  const [customerTotal, setCustomerTotal] = useState(0);
  const [orderPayment, setOrderPayment] = useState(null);

  const [fulfillmentOrderRequestStatus, setFulfillmentOrderRequestStatus] =
    useState("");
  const [fulfillmentOrderStatus, setFulfillmentOrderStatus] = useState("");

  useEffect(() => {
    if (order !== null) {
      let tempSubTotal = 0;
      let tempCustomerSubtotal = 0;
      let totalWeightGrams = 0;

      order.line_items.forEach((item) => {
        if (item.jemmity_data) {
          tempSubTotal += item.vendor_price * item.quantity;
          tempCustomerSubtotal += item.price * item.quantity;
          totalWeightGrams += item.grams;
        }
      });
      setSubTotal(tempSubTotal);

      //setshippingPrice(1.0);
      try {
        const country = order.shipping_address.country.toUpperCase();
        //console.log("country = " + country);
        const weightKg = parseFloat(totalWeightGrams) / 1000;
        //console.log("weightKg = " + weightKg);
        const won = getShippingPriceWon(weightKg, country);
        // console.log("won = " + won);
        // console.log("cad rate = " + krwRateInfo["cad"]);
        const cad = won * krwRateInfo["cad"];
        //console.log("cad = " + cad);
        setshippingPrice(cad);
      } catch (e) {}

      setTax(0);
      //setTax(order.total_tax);

      setTotal(subTotal + shippingPrice);
      setCustomerTotal(tempCustomerSubtotal);

      setProfit(order.total_price_set.shop_money.amount - total);

      try {
        if (
          order.hasOwnProperty("fulfillment_request_response") &&
          order.fulfillment_request_response !== null &&
          order.fulfillment_request_response.hasOwnProperty("request_status")
        ) {
          setFulfillmentOrderRequestStatus(
            order.fulfillment_request_response.request_status
          );
          setFulfillmentOrderStatus(order.fulfillment_request_response.status);
        } else if (
          order.hasOwnProperty("fulfillment_orders") &&
          order.fulfillment_orders !== null
        ) {
          if (order.fulfillment_orders.length === 1) {
            setFulfillmentOrderRequestStatus(
              order.fulfillment_orders[0].request_status
            );
            setFulfillmentOrderStatus(order.fulfillment_orders[0].status);
          } else if (order.fulfillment_orders.length > 1) {
            const fulOrd = order.fulfillment_orders.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            )[0];

            setFulfillmentOrderRequestStatus(fulOrd.request_status);
            setFulfillmentOrderStatus(fulOrd.status);
          }
        }
      } catch (e) {
        //console.error(e);
      }
    }

    // dispatch({
    //   type: "resetOptions",
    //   value: {
    //     ...options,
    //     currency: currency,
    //   },
    // });
  }, [order, subTotal, shippingPrice, total, tax]);

  useEffect(() => {
    if (order !== null && orderPayment === null) {
      try {
        httpCommon
          .get(`/orders/orderpayment-by-id/${order.id}`)
          .then((res) => {
            //console.log("orderpayment-by-id");
            //console.log(res.data);
            setOrderPayment(res.data);
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (e) {}
    }
  }, [order]);

  return (
    <OrderItemContainer className="">
      <td>
        <input type="checkbox" />
      </td>
      <td>
        <span className="badge bg-info text-black" style={{ fontSize: "15px" }}>
          {order.order_number}
        </span>
      </td>
      <td style={{ whiteSpace: "nowrap" }}>{order.created_at.substr(0, 10)}</td>
      <td>
        {customer
          ? customer.first_name + " " + customer.last_name
          : "Customer info not found"}
      </td>
      <td>{order.financial_status === "paid" ? "Paid" : "Pending"}</td>
      <td>
        ${Number(customerTotal).toFixed(2)}{" "}
        {order.total_price_set.shop_money.currency_code}
      </td>
      <td style={{ color: "orangered", fontWeight: "700" }}>
        ${Number(total).toFixed(2)} CAD
      </td>
      <td>${Number(profit).toFixed(2)} CAD</td>
      <td>
        <span style={{ textTransform: "capitalize" }}>
          {fulfillmentOrderRequestStatus.replace("_", " ")}
        </span>{" "}
        /{" "}
        <span style={{ textTransform: "capitalize" }}>
          {fulfillmentOrderStatus.replace("_", " ")}
        </span>
      </td>
      <td>
        {order.hasOwnProperty("jemmity_status") &&
        order.jemmity_status !== undefined &&
        order.jemmity_status !== null
          ? order.jemmity_status
          : "Not paid"}
      </td>
      <td>
        {order.jemmity_status !== undefined &&
        order.jemmity_status !== null &&
        orderPayment !== null &&
        orderPayment.payment_ref_number !== "" ? (
          <a
            href={`/orders/payment/success/${orderPayment.payment_ref_number}`}
            rel={"noreferrer"}
            target={"_blank"}
            className="btn btn-primary btn-sm"
          >
            View payment confirmation
          </a>
        ) : null}
        {order.jemmity_status === undefined || order.jemmity_status === null ? (
          <button
            className="btn btn-primary btn-sm"
            //disabled={checkOutVisible}
            onClick={(event) => {
              event.preventDefault();
              handleShowCheckoutModal(order);
            }}
            type="button"
          >
            Check Out
          </button>
        ) : null}
      </td>
      {/*<div className="row order-header m-0">
        <div className="col-1">
          <input type="checkbox" />
        </div>
        <div className="col-2 order-number">Order #{order.order_number}</div>
        <div className="col-2 order-date">
          Date: {order.created_at.substr(0, 10)}
        </div>
        <div className="col-3 fw-bold">
          Customer Payment Status:{" "}
          {order.financial_status === "paid" ? "Paid" : "Pending"}
        </div>
        <div className="col-4">
          <div className="float-end">
            <button
              disabled={checkOutVisible}
              className="btn btn-primary me-2"
              onClick={(event) => {
                event.preventDefault();
                handleShowCheckoutModal(order);
              }}
              type="button"
            >
              Check Out
            </button>

            <OverlayTrigger
              trigger="click"
              placement="left"
              overlay={
                <Popover id="popover-positioned-left" placement="left">
                  <Popover.Header as="h3">Customer Info</Popover.Header>
                  <Popover.Body>
                    {customer
                      ? customer.first_name + " " + customer.last_name
                      : "Customer info not found"}
                  </Popover.Body>
                </Popover>
              }
            >
              <button className="btn btn-success">
                Customer Info
              </button>
            </OverlayTrigger>
          </div>
        </div>
      </div>
      <div className="row order-table m-2">
        <table>
          <thead>
            <tr>
              <th>Images</th>
              <th>Product</th>
              <th>Quantity</th>
              <th className="price">Cost</th>
              <th className="price">Store Price</th>
              <th>Supplier</th>
            </tr>
          </thead>
          <tbody>
            {line_items.map((item) => {
              return item.product_id !== null ? (
                <tr key={item.id}>
                  <td>
                    <img src="https://via.placeholder.com/50" fluid />
                  </td>
                  <td>{item.name}</td>
                  <td>{item.quantity}</td>
                  <td className="price">
                    ${Number(item.vendor_price).toFixed(2)}
                  </td>
                  <td className="price">${Number(item.price).toFixed(2)}</td>
                  <td>{item.vendor || "N/A"}</td>
                </tr>
              ) : (
                <tr>
                  <td className="alert alert-warning pt-2 pb-2" colSpan={6}>
                    Product deleted
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
          <div className="row btnfooter"></div>*/}
    </OrderItemContainer>
  );
};
