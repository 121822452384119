import React, { Fragment, useEffect, useState } from "react";
import { UserContext } from "../../App";
import httpCommon from "../../http-common";
import Profile from "../../components/profile/profile.component";

export const SettingsPage = (props) => {
  const [markupTypes, setMarkupTypes] = useState([]);

  const getMarkupTypes = async () => {
    // try {
    //   await httpCommon
    //     .get("/settings/markup-types")
    //     .then((res) => {
    //       console.log(res);
    //       return res;
    //     })
    //     .then((res) => {
    //       console.log(res.data);
    //       setMarkupTypes(res.data);
    //     });
    // } catch (err) {
    //   console.error(err.message);
    // }
  };

  useEffect(() => {
    getMarkupTypes();
  }, []);

  return (
    <Fragment>
      <div
        className="sticky-md-top"
        style={{
          backgroundColor: "#f3f3f3",
          boxShadow: "0 .5rem 1rem -.5rem rgba(0,0,0,.15)",
        }}
      >
        <div className="row">
          <div className="col-12 mt-3 mb-1 ms-3">
            <h4 className="text-uppercase">Settings</h4>
          </div>
        </div>
      </div>
      <div className="container mt-3">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              id="profile-tab"
              data-toggle="tab"
              href="#profile"
              role="tab"
              aria-controls="profile"
              aria-selected="true"
            >
              Profile
            </a>
          </li>
          <li className="nav-item d-none">
            <a
              className="nav-link"
              id="auto-updates-tab"
              data-toggle="tab"
              href="#auto-updates"
              role="tab"
              aria-controls="auto-updates"
              aria-selected="false"
            >
              Auto updates IMA
            </a>
          </li>
          <li className="nav-item d-none">
            <a
              className="nav-link"
              id="pricing_rules"
              data-toggle="tab"
              href="#pricing-rules"
              role="tab"
              aria-controls="pricing-rules"
              aria-selected="false"
            >
              Pricing Rules
            </a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active p-4"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <UserContext.Consumer>
              {(value) => <Profile userCtx={value} />}
            </UserContext.Consumer>
            {/*<table className="table mt-3">
                <tr>
                  <td className="bg-light text-left font-weight-bold">
                    Your name
                  </td>
                  <td>
                    <input
                      type="text"
                      value="test-store-name"
                      className="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="bg-light text-left font-weight-bold">Email</td>
                  <td>
                    <input
                      type="email"
                      value="test@test.com"
                      className="form-control"
                    />
                  </td>
                </tr>
              </table>
              <button type="button" className="btn btn-primary">
                Save changes
        </button>*/}
          </div>
          <div
            className="tab-pane fade"
            id="auto-updates"
            role="tabpanel"
            aria-labelledby="auto-updates-tab"
          >
            <table className="table mt-3 text-left">
              <tr>
                <td className="bg-light">
                  <label className="p-0 m-0 font-weight-bold">
                    When product is no longer available
                  </label>
                  <div className="p-0 mt-1">
                    <input type="checkbox" />
                    <label className="ml-1">Notify me</label>
                  </div>
                </td>
                <td>
                  Choose an action when one of your products is no longer
                  available from the supplier. Applies to all existing products.
                  <div className="p-0 mt-2">
                    <button type="button" className="btn btn-warning">
                      Do nothing
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary ml-1"
                    >
                      Unpublish product
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary ml-1"
                    >
                      Set quantity to zero
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="bg-light">
                  <label className="p-0 m-0 font-weight-bold">
                    When variant is no longer available
                  </label>
                  <div className="p-0 mt-1">
                    <input type="checkbox" />
                    <label className="ml-1">Notify me</label>
                  </div>
                </td>
                <td>
                  Choose an action when one of the product's variants is no
                  longer available from the supplier.
                  <div className="p-0 mt-2">
                    <button type="button" className="btn btn-warning">
                      Do nothing
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary ml-1"
                    >
                      Unpublish product
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary ml-1"
                    >
                      Set quantity to zero
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="bg-light">
                  <label className="p-0 m-0 font-weight-bold">
                    When the cost changes
                  </label>
                  <div className="p-0 mt-1">
                    <input type="checkbox" />
                    <label className="ml-1">Notify me</label>
                  </div>
                </td>
                <td>
                  Choose an action when the cost of your product changes.
                  <div className="p-0 mt-2">
                    <button type="button" className="btn btn-warning">
                      Do nothing
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary ml-1"
                    >
                      Update 'Price' and 'Compared at price'
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary ml-1"
                    >
                      Update 'Price' only
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="bg-light">
                  <label className="p-0 m-0 font-weight-bold">
                    When inventory changes
                  </label>
                  <div className="p-0 mt-1">
                    <input type="checkbox" />
                    <label className="ml-1">Notify me</label>
                  </div>
                </td>
                <td>
                  Choose an action when the inventory level of a particular
                  product changes.
                  <div className="p-0 mt-2">
                    <button type="button" className="btn btn-warning">
                      Do nothing
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary ml-1"
                    >
                      Update automatically
                    </button>
                  </div>
                </td>
              </tr>
            </table>
            <button type="button" className="btn btn-primary">
              Save changes
            </button>
          </div>
          <div
            className="tab-pane fade"
            id="pricing-rules"
            role="tabpanel"
            aria-labelledby="pricing-rules-tab"
          >
            <div className="text-left mt-3">
              <strong>Pricing Rules</strong> Set your product markup depending
              on cost ranges.
              <table className="table mt-3">
                <tr>
                  <td className="bg-light font-weight-bold">Markup</td>
                  <td>
                    <input type="text" value="2.00" className="form-control" />
                  </td>
                </tr>
                <tr>
                  <td className="bg-light text-left font-weight-bold">
                    Markup Type
                  </td>
                  <td>
                    <select className="form-select">
                      {markupTypes.map((markupType) => (
                        <option value={markupType.markup_type_id}>
                          {markupType.markup_type_name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="bg-light text-left font-weight-bold">
                    Advanced Pricing Rules
                  </td>
                  <td>
                    <div className="d-flex">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="option1"
                          checked
                        />
                        <label
                          className="form-check-label"
                          for="exampleRadios1"
                        >
                          Active
                        </label>
                      </div>
                      <div className="form-check ml-4">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios2"
                          value="option2"
                        />
                        <label
                          className="form-check-label"
                          for="exampleRadios2"
                        >
                          Inactive
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
              <table className="table">
                <thead className="bg-light">
                  <tr>
                    <th>Cost range</th>
                    <th>Markup</th>
                    <th>
                      <button type="button" className="btn btn-primary btn-sm">
                        Add
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="d-flex align-items-center">
                      <input type="number" className="form-control" />
                      <span className="ml-2 mr-2">-</span>
                      <input type="number" className="form-control" />
                    </td>
                    <td>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">X</span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          aria-label="Amount (to the nearest dollar)"
                        />
                        <div className="input-group-append">
                          <select className="form-select bg-secondary text-white">
                            <option value="1" selected>
                              Multiplier
                            </option>
                            <option value="2">Fixed markup</option>
                          </select>
                        </div>
                      </div>
                    </td>
                    <td>
                      <button type="button" className="btn btn-secondary">
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <button type="button" className="btn btn-primary mt-3">
              Save changes
            </button>
          </div>
        </div>
      </div>
      <div className="text-center mt-5">
        <p>
          Copyright {new Date().getFullYear()}, Jemmity. All rights reserved.
          <br />
          <a
            href="https://jemmity.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </Fragment>
  );
};
